<template>
  <div class="panel-header">
    <div
      v-if="step > 1"
      tabindex="0"
      role="button"
      aria-label="Go to previous step"
      class="panel-header__icon-wrapper"
      @keypress.enter="onBackClick()"
      @keypress.space="onBackClick()"
      @click="onBackClick()"
    >
      <icons-back color="#62646A" />
    </div>

    <slot />

    <div
      tabindex="0"
      role="button"
      aria-label="Close panel"
      class="panel-header__icon-wrapper"
      @keypress.enter="onCloseClick()"
      @keypress.space="onCloseClick()"
      @click="onCloseClick()"
    >
      <icons-close color="#62646A" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelHeader',
  emits: [
    'update:show',
    'update:step',
  ],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  methods: {
    onBackClick () {
      const { step } = this

      this.$emit('update:step', step - 1)
    },
    onCloseClick () {
      this.$emit('update:show', false)
    }
  },
}
</script>

<style lang="scss" scoped>
.panel-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;

  font-family: 'Lato-Light', sans-serif;
  font-size: 40px;
  line-height: 44px;
  color: #000000;

  &__icon-wrapper {
    cursor: pointer;
    display: flex;
    min-width: 40px;
    height: 40px;
    background: inherit;
    transition: background ease-in-out 0.26s;
    border-radius: 44px;
    justify-content: center;
    align-items: center;
    outline: none;

    &:hover,
    &:focus {
      background: #F4F4F4;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }
}
</style>
