<template>
  <v-select
    v-model="modelValue"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details
    dense
    outlined
    solo
    flat
    :attach="!disableAttach && attachTo"
    color="black"
    item-color="black"
    no-data-text="No data"
    class="ma-0 thin-border rounded-0 common-select"
    :class="{ 'common-select--filter' : showFilterStyle }"
    append-icon="mdi-chevron-down"
    @click.native="onTimeFrameSelectClick"
    ref="common-select"
    :height="height"
    :multiple="multiple"
    :menu-props="{
      bottom: !top,
      top: top,
      offsetY: true,
      left: true,
      tile: true,
      zIndex: menuZIndex,
      contentClass: contentClass
    }"
  >
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot" />
      </template>
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CommonSelect',
  props: {
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    height: {
      type: [String, Number],
      required: false,
      default: 36
    },
    menuZIndex: {
      type: Number,
      required: false,
      default: null
    },
    menuContentClass: {
      type: String,
      required: false,
      default: ''
    },
    closeOnInputClick: {
      type: Boolean,
      required: false,
      default: true
    },
    disableAttach: {
      type: Boolean,
      required: false,
      default: false
    },
    filterStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    top: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    attachTo: null,
    numberOfClicks: 0
  }),
  computed: {
    modelValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    contentClass () {
      const { menuContentClass } = this
      return menuContentClass + ' common-select-items'
    },
    showFilterStyle () {
      const { filterStyle, modelValue, multiple } = this

      return multiple ? filterStyle && modelValue?.length : filterStyle && modelValue
    },
  },
  mounted () {
    // attach v-menu to v-select element when element is mounted
    this.attachTo = this.$el
  },
  methods: {
    // when user click on select element and it is in an open state another click will close it
    onTimeFrameSelectClick () {
      const { closeOnInputClick, multiple } = this
      // disable for multiple select
      if (multiple) { return }

      this.numberOfClicks += 1
      if (this.numberOfClicks === 2) {
        if (closeOnInputClick) this.$refs['common-select'].blur()
        this.numberOfClicks = 0
      }
    },
    closeMenu () {
      this.$refs['common-select'].blur()
      this.numberOfClicks = 0
    }
  }
}
</script>

<style lang="scss">
.v-text-field {
  > .v-input__control {
    > .v-input__slot:after {
      border: none;
    }
  }
}

.v-input__slot {
  &::before {
    border-color: rgba(0, 0, 0, 0) !important;
  }
}

.v-select__slot .v-input__icon i {
  margin-left: 0px;

  &::before {
    color: #919191;
    font-size: 24px;
  }
}

.common-select-items {
  padding: 0;

  .v-list {
    padding: 0;

    .v-list-item {
      padding: 2px 10px;
      max-height: 40px;
      min-height: 40px;
      font-size: 14px;
      font-family: 'Lato-Regular', sans-serif;
      color: #222325;

      &__title {
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }

      &--active {
        background: #EDFCFB;

        &::before {
          opacity: 0;
        }
      }

      &[aria-disabled="true"] {
        color: rgba(0, 0, 0, 0.38);
      }

      &:hover {
        background-color: #FAFAFA;
        color: #222325;
      }
    }
  }
}

.common-select {
  &.v-text-field--outlined {
    .v-input__append-inner {
      margin-top: 0px !important;
    }
    .v-input__icon {
      padding-left: 14px;
    }
  }
}

.common-select--filter {
  & .v-input__slot {
    background-color: rgb(237, 252, 251) !important;
  }
}
</style>

<style lang="scss" scoped>
.v-input {
  background-color: #fff;
}
</style>
