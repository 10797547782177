<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="more-info-panel"
  >
    <close-panel-button @close="() => show = false">Close</close-panel-button>

    <v-container class="px-9 py-0">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-img
                :alt="productName"
                :src="productImage"
                position="top center"
                contain
                max-height="450"
                width="100%"
                max-width="100%"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" class="more-info-panel__product-name">
                  {{ productName }}
                </v-col>
                <v-col v-if="productLink" cols="12" class="py-0">
                  <a
                    :href="productLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="more-info-panel__product-link"
                  >
                    View product page
                  </a>
                </v-col>
                <v-form ref="form">
                  <v-col
                    v-for="(option, index) in productConfigurableOptions"
                    :key="index"
                    cols="12"
                  >
                    <label :for="option.code">{{ option.label }}</label>
                    <common-select
                      v-model="formValues[option.code]"
                      :items="option.options"
                      :rules="[rules.required]"
                      id="option.code"
                      item-text="name"
                    />
                  </v-col>
                </v-form>
                <v-col cols="12" class="pt-8" v-if="!$vuetify.breakpoint.xsOnly">
                  <campaign-card-button
                    @showSendEGiftPanel="showSendEGift"
                    @showSendDirectPanel="$emit('showSendDirectPanel')"
                    :disabled="productIsOOS"
                    :items="[]"
                    style="position: relative"
                    v-if="productSendAsEgift && !productIsCustomized"
                  />
                  <common-button
                    block
                    :disabled="productIsOOS"
                    class="favorites-button"
                    @click="addToCart()"
                    v-else
                  >
                    {{ productIsCustomized ? 'Customize / Request a sample' : 'Add to cart' }}
                  </common-button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="py-8">
          <v-divider />
        </v-col>

        <v-col cols="12">
          <span class="more-info-panel__description" v-html="productDescription" />
        </v-col>

        <v-col cols="12" class="pt-8" v-if="$vuetify.breakpoint.xsOnly">
          <campaign-card-button
            @showSendEGiftPanel="showSendEGift"
            @showSendDirectPanel="$emit('showSendDirectPanel')"
            :disabled="productIsOOS"
            :items="[]"
            style="position: relative"
            v-if="productSendAsEgift && !productIsCustomized"
          />

          <common-button
            block
            :disabled="productIsOOS"
            class="favorites-button"
            @click="addToCart()"
            v-else
          >
            {{ productIsCustomized ? 'Customize / Request a sample' : 'Add to cart' }}
          </common-button>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import updateCartAmount from '@/mixins/updateCartAmount'

import CampaignCardButton from '@/components/myCampaign/CampaignCardButton.vue'

export default {
  name: 'TheMyFavoritesMoreInfoPanel',
  mixins: [
    panelVModel,
    updateCartAmount,
  ],
  components: {
    CampaignCardButton,
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    imageUrl: 'https://cf.corporategift.com/media/catalog/product',
    rules: { required: v => v !== null || '' },
    formValues: {},
  }),
  computed: {
    productIsCustomized () {
      return this.product?.product?.should_be_customized ?? false
    },
    productIsOOS () {
      return this.product?.product?.qty.is_out_of_stock ?? false
    },
    productIsConfigurable () {
      return this.product?.product?.type.is_configurable ?? false
    },
    productConfigurableOptions () {
      return []
    },
    productSendAsEgift () {
      return this.product?.product?.send_as_egift ?? false
    },
    productName () {
      return this.product?.product?.name ?? null
    },
    productLink () {
      return this.product?.link ?? null
    },
    productImage () {
      const image = this.product?.product?.image ?? null
      if (!image) return '/images/empty-order.png'
      return image.includes('no_selection') ? '/images/empty-order.png' : this.imageUrl + image
    },
    productDescription () {
      return this.product?.product?.description ?? null
    }
  },
  watch: {
    show: function (val) {
      const { productIsConfigurable, productConfigurableOptions } = this
      if (val) {
        if (productIsConfigurable) {
          productConfigurableOptions.forEach((item) => {
            this.formValues[item.code] = null
          })
        }
      }
    }
  },
  methods: {
    addToCart () {
      const {
        productIsCustomized,
        productIsConfigurable,
        product: {
          link,
          product: { min_qty: minQty, },
          product_id: productId,
        },
      } = this

      if (!this.$refs.form.validate()) return;

      if (productIsCustomized || productIsConfigurable) {
        window.open(link, '_blank')
      } else {
        this.$cgToast.information('Adding product to cart')
        // TODO add options
        Api.post('/cart/items', [{ qty: minQty, product_id: Number(productId), redirect_url: link }])
          .then(() => {
            this.$cgToast.successBold('Successfully added product to cart')

            this.updateCartAmount()
          })
          .catch((e) => {
            console.error(e)
            this.$cgToast.error('An error occurred, please contact our support')
          })
      }
    },
    showSendEGift (type) {
      this.show = false
      this.$emit('showEGiftPanel', this.product, type)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/favorites-button.scss";

.more-info-panel {
  &__product-name {
    font-size: 25px;
    line-height: 35px;
    font-family: 'Lato-Regular', sans-serif;
    color: #161616;
  }

  &__product-link {
    font-family: 'Lato-Regular', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #42B77A !important;
  }

  &__description {
    font-size: 15px;
    line-height: 24px;
    font-family: 'Lato-Regular', sans-serif;
    font-weight: normal;
    letter-spacing: normal;
    color: #16181F;

    &::v-deep strong {
      font-family: 'Lato-Bold', sans-serif;
    }

    &::v-deep ul {
      padding: 0;

      & > li {
        list-style: none !important;
      }
    }

    &::v-deep p {
      margin-bottom: 36px;
    }
  }

  &::v-deep .btn-menu {
    width: 100%;
    top: 100%;
  }

  &::v-deep button {
    text-transform: unset;
    font-size: 18px;
    height: 44px !important;
    line-height: 22px;
  }

  & form {
    width: 100%;
  }

  & label {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #000;
  }
}
</style>
