<template>
  <v-container class="pa-0 ma-0">
    <v-row no-gutters class="table">
      <v-col class="table__column-wrapper">
        <div class=" table__header">
          #
        </div>

        <div
          class="table__column"
          :class="tableFirstName && 'table__column--active'"
        >
          <template v-for="(item, index) in tableItems">
            <div class="pa-1 column--item text-no-wrap" :key="index">
              {{ index + 1 }}
            </div>
          </template>
        </div>
      </v-col>
      <v-col class="table__column-wrapper">
        <div class="table__header">
          First name

          <span v-if="form.firstName" @click="clearFirstName()">
            Clear
          </span>

        </div>
        <div
          class="table__column table__column--without-left-border"
          :class="tableFirstName && 'table__column--active'"
        >
          <input-wrapper
            v-model="tableFirstName"
            v-if="!tableFirstName"
            ref="firstNameColumn"
          >
            Paste Here list of First names
          </input-wrapper>

          <template v-for="(item, index) in tableItems" v-else>
            <div class="px-2 py-1 column--item" :key="`firstname-${index}`">
              {{ item.first_name }}
            </div>
          </template>
        </div>
      </v-col>
      <v-col class="table__column-wrapper">
        <div class="table__header">
          Last name

          <span v-if="form.lastName" @click="clearLastName()">
            Clear
          </span>
        </div>
        <div
          class="table__column table__column--without-left-border"
          :class="tableLastName && 'table__column--active'"
        >
          <input-wrapper
            v-model="tableLastName"
            v-if="form.firstName && !tableLastName"
            ref="lastNameColumn"
          >
            Paste Here list of Last names
          </input-wrapper>

          <template v-for="(item, index) in tableItems" v-else>
            <div class="px-2 py-1 column--item" :key="`lastname-${index}`">
              {{ item.last_name }}
            </div>
          </template>
        </div>
      </v-col>
      <v-col class="table__column-wrapper">
        <div class="table__header">
          Email
          {{ isSendAsLink ? '(optional)' : null }}

          <span v-if="form.email" @click="clearEmails()">
            Clear
          </span>
        </div>
        <div
          class="table__column table__column--without-left-border"
          :class="tableEmail && 'table__column--active'"
        >
          <input-wrapper
            v-model="tableEmail"
            v-if="(form.firstName || form.lastName) && !tableEmail"
            ref="emailColumn"
          >
            Paste Here list of Emails
          </input-wrapper>

          <template v-for="(item, index) in tableItems" v-else>
            <div class="px-2 py-1 column--item" :key="`email-${index}`">
              {{ item.email }}
            </div>
          </template>
        </div>
      </v-col>

      <v-col v-if="!isSendAsLink" class="table__column-wrapper">
        <div class="table__header">
          Send date (optional)

          <span v-if="form.sendDate" @click="clearSendDates()">
            Clear
          </span>
        </div>
        <div
          class="table__column table__column--without-left-border"
          :class="tableSendDate && 'table__column--active'"
        >
          <input-wrapper
            v-model="tableSendDate"
            v-if="(form.firstName || form.lastName) && tableEmail && !tableSendDate"
            ref="sendDateColumn"
          >
            Paste Here list of send dates
            <br>
            <br>
            mm/dd/yyyy
          </input-wrapper>

          <template v-for="(item, index) in tableItems" v-else>
            <div class="px-2 py-1 column--item" :key="`email-${index}`">
              <template v-if="item.send_date">
                {{ item.send_date | dateFilter }}
              </template>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters :class="{ 'address-book-sticky-button': makeCTABtnSticky }">
      <v-col cols="12" class="pt-6 mt-2 pb-3" >
        <common-button
          outlined
          height="45"
          @click="$emit('go-back')"
        >
          Back
        </common-button>

        <common-button
          height="45"
          class="ml-2"
          @click="goToNextStep()"
          :disabled="!makeCTABtnSticky"
        >
          Continue to review recipients
        </common-button>

        <div>
          <div class="table-info-text pt-4 d-flex align-center">
            <icons-info width="22" height="22" class="mr-2" />

            Make sure that all columns are lined up correctly, before continuing to the next step
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputWrapper from './ThePasteRecipientsInputWrapper.vue'

import takeCurrentDate from '@/components/myCampaign/panels/mixins/takeCurrentDate'
import panelType from '../../mixins/panelType'

export default {
  name: 'ThePasteRecipientsTable',
  components: {
    InputWrapper
  },
  mixins: [
    takeCurrentDate,
    panelType
  ],
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    form: {
      firstName: null,
      lastName: null,
      email: null,
      sendDate: null
    }
  }),
  computed: {
    isSendAsLink () {
      return this.panelType === 'link'
    },
    tableItems: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    tableFirstName: {
      get () {
        return this.form.firstName
      },
      set (val) {
        !this.isValueASpecialChar(val)
          ? this.setTableItemsColumn(val, 'first_name')
          : this.fillTableColumns('first_name', null)

        this.form.firstName = val

        if (this.isSendAsLink) {
          this.fillTableColumns('send_date', this.takeCurrentDate())
          this.form.sendDate = 'filled!'
        }

        if (!this.form.lastName) {
          setTimeout(() => {
            this.$refs.lastNameColumn?.focuesTextField()
          }, 0)
        }
      }
    },
    tableLastName: {
      get () {
        return this.form.lastName
      },
      set (val) {
        !this.isValueASpecialChar(val)
          ? this.setTableItemsColumn(val, 'last_name')
          : this.fillTableColumns('last_name', null)

        this.form.lastName = val

        if (!this.form.email) {
          setTimeout(() => {
            this.$refs.emailColumn?.focuesTextField()
          }, 0)
        }
      }
    },
    tableEmail: {
      get () {
        return this.form.email
      },
      set (val) {
        !this.isValueASpecialChar(val)
          ? this.setTableItemsColumn(val, 'email')
          : this.fillTableColumns('email', null)

        this.form.email = val

        if (!this.form.sendDate) {
          setTimeout(() => {
            this.$refs.sendDateColumn?.focuesTextField()
          }, 0)
        }
      }
    },
    tableSendDate: {
      get () {
        return this.form.sendDate
      },
      set (val) {
        !this.isValueASpecialChar(val)
          ? this.setTableItemsColumn(val, 'send_date')
          : this.fillTableColumns('send_date', this.takeCurrentDate())

        this.form.sendDate = val
      }
    },
    isFormCompleted () {
      const { firstName, lastName, email } = this.form
      return !Object.values({
        firstName,
        lastName,
        email
      }).some(v => v)
    },
    makeCTABtnSticky () {
      const { tableFirstName, tableEmail, isSendAsLink } = this

      return !!tableFirstName && isSendAsLink ? true : !!tableEmail
    }
  },
  mounted () {
    if (this.value.length) {
      this.form = {
        firstName: this.value[0].first_name ? 'filled' : null,
        lastName: this.value[0].last_name ? 'filled' : null,
        email: this.value[0].email ? 'filled' : null,
        sendDate: this.value[0].send_date ? 'filled' : null
      }
    } else {
      this.$refs.firstNameColumn?.focuesTextField()
    }
  },
  methods: {
    isValueASpecialChar (value) {
      return value.match(/^[^a-zA-Z0-9]+$/)
    },
    isDateValid (sendDate) {
      if (!sendDate) { return false }
      return new Date(sendDate) >= new Date(this.takeCurrentDate())
    },
    setTableItemsColumn (columnData, columnName) {
      // split csv columns
      const columnArray = columnData.split('\n')
      // remove the last element when it is empty
      if (!columnArray[columnArray.length - 1]) columnArray.pop()

      columnArray.forEach((item, index) => {
        if (this.tableItems[index]) {
          if (columnName === 'send_date') {
            this.tableItems[index][columnName] = this.isDateValid(item)
              ? new Date(item).toISOString()
              : this.takeCurrentDate()
          } else {
            this.tableItems[index][columnName] = item
          }
        } else {
          const obj = {}
          obj[columnName] = item
          this.tableItems[index] = obj
        }
      })
    },
    fillTableColumns (columnName, fillWith = null) {
      this.tableItems.forEach((item, index) => {
        this.tableItems[index][columnName] = fillWith
      })
    },
    shouldClearTableItems () {
      if (this.isFormCompleted) {
        this.tableItems = []
      }
    },
    clearFirstName () {
      this.fillTableColumns('first_name', null)
      this.form.firstName = null
      this.shouldClearTableItems()
    },
    clearLastName () {
      this.fillTableColumns('last_name', null)
      this.form.lastName = null
      this.shouldClearTableItems()
    },
    clearEmails () {
      this.fillTableColumns('email', null)
      this.form.email = null
      this.shouldClearTableItems()
    },
    clearSendDates () {
      this.fillTableColumns('send_date', null)
      this.form.sendDate = null
      this.shouldClearTableItems()
    },
    goToNextStep () {
      if (!this.form.sendDate) {
        this.fillTableColumns('send_date', this.takeCurrentDate())
      }

      this.$emit('organize-file', this.tableItems)
    },
  }
}
</script>

<style lang="scss" scoped>
.table {
  flex-wrap: nowrap;

  &__header {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 600;
    font-size: 11px;
    padding: 10px !important;
    line-height: 13px;
    color: #919191;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    min-height: 40px;
    text-align: left;
    text-transform: uppercase;
    border-top: thin solid rgba(0, 0, 0, 0.12);

    &--active {
      color: #000;
    }

    span {
      text-transform: capitalize;
      font-size: 14px;
      color: #219358;
      line-height: 18px;
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      cursor: pointer;
    }
  }

  &__column {
    border: 1px dashed rgba(0, 0, 0, 0.12);
    min-height: 305px;
    height: calc(100% - 34px);
    width: 100%;
    background-color: #F6F6F6;

    &--without-left-border {
      border-left: none !important;
    }

    &--active {
      border-style: solid;
      background-color: #fff;
      color: #000;
    }

    .column--item {
      min-height: 32px;

      &:nth-child(even) {
        background-color: #F6F6F6;
      }

      &--no-background {
        background-color: unset !important;
        font-family: 'Lato-Italic', sans-serif !important;
        font-weight: 400 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-size: 15px;
        text-transform: none !important;
      }
    }
  }

  &__column-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 1 200px;

    &:nth-child(1) {
      flex: 0 1 36px;
    }

    &:nth-child(4) {
      flex: 1 1 300px;
    }
  }
}

.table-info-text {
  font-family: 'Lato-Italic', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9F9F9F;
}

.address-book-sticky-button {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 4;
}
</style>
