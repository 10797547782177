var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{directives:[{name:"lock-scroll",rawName:"v-lock-scroll",value:(_vm.show),expression:"show"}],staticClass:"campaign-settings-panel",attrs:{"app":"","temporary":"","right":"","width":"850px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('close-panel-button',{attrs:{"text":"Close"},on:{"close":() => _vm.show = false}}),(_vm.loading)?_c('common-loader',{staticStyle:{"max-height":"800px"}}):_vm._e(),(!_vm.loading && _vm.gift && _vm.show)?_c('v-container',{staticClass:"px-9 py-0"},[_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"campaign-settings-panel__title",attrs:{"cols":"12"}},[_vm._v(" Edit settings for "),_c('span',{staticClass:"campaign-settings-panel__title--bold"},[_vm._v(" "+_vm._s(_vm.gift.name)+" ")])]),_c('v-col',{staticClass:"d-flex align-center g20",attrs:{"cols":"6"}},[_c('label',{staticClass:"gray4 f14 pointer",attrs:{"for":"status-checkbox"}},[_c('strong',{staticClass:"f15 black1"},[_vm._v("Status:")]),_vm._v(" "+_vm._s(_vm.isCampaignActive ? 'Active' : 'Disabled')+" ")]),_c('common-switch',{attrs:{"id":"status-checkbox"},model:{value:(_vm.isCampaignActive),callback:function ($$v) {_vm.isCampaignActive=$$v},expression:"isCampaignActive"}})],1),_c('v-col',{staticClass:"pt-8 pb-14",attrs:{"cols":"7"}},[_c('label',{attrs:{"for":"tags"}},[_vm._v(" Tags associated with campaign/s: ")]),_c('v-combobox',{ref:"tags-select",staticClass:"campaigns-tags-combobox rounded-0 pt-1",attrs:{"multiple":"","small-chips":"","clearable":"","items":_vm.availableTags,"append-icon":"mdi-chevron-down","color":"#62646A","outlined":"","dense":"","item-color":"#62646A","item-value":"id","item-text":"name","hide-details":"","menu-props":{
            bottom: true,
            offsetY: true,
            left: true,
            tile: true,
            contentClass: 'campaigns-tags-combobox__select'
          }},on:{"input":_vm.handleInputChange},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item, parent, selected }){return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"d-flex align-center pointer",on:{"click":function($event){return parent.selectItem(item)}}},[_c('icons-close',{attrs:{"width":10,"height":11}})],1)]):_vm._e()]}},(_vm.userIsAnAdmin)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"px-4 pt-4 pb-2 campaigns-tags-combobox__new-tag",class:{ 'campaigns-tags-combobox__new-tag--active': _vm.addNewTag }},[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){_vm.addNewTag = !_vm.addNewTag}}},[_c('icons-plus-circle',{attrs:{"width":28,"height":28}}),(!_vm.addNewTag)?[_vm._v(" Add new tag ")]:_vm._e()],2),(_vm.addNewTag)?_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('common-input',{attrs:{"height":30,"error":_vm.showNewTagError},model:{value:(_vm.newTagName),callback:function ($$v) {_vm.newTagName=$$v},expression:"newTagName"}}),_c('common-button',{attrs:{"height":30,"loading":_vm.loadingNewTag},on:{"click":function($event){return _vm.sendNewTag()}}},[_vm._v(" Add tag ")])],1):_vm._e()])]},proxy:true}:null,{key:"item",fn:function({ item, attrs }){return [_c('div',{staticClass:"campaigns-tags-combobox__select--item"},[_c('div',{staticClass:"campaigns-tags-combobox__select--item--icon",class:{
                  'campaigns-tags-combobox__select--item--icon--selected': attrs['aria-selected'] === 'true'
                }}),_c('div',{staticClass:"campaigns-tags-combobox__select--item--name"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"gray3 f14 px-4 py-2"},[_vm._v(" No data ")])]},proxy:true}],null,true),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('label',{staticClass:"d-flex align-center g10"},[_vm._v(" Users assigned to this campaign "),_c('common-badge',[_vm._v(" "+_vm._s(_vm.selectedUsers.length)+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('add-users',{attrs:{"items":_vm.availableUsers},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)]),_c('v-col',{staticClass:"py-5",attrs:{"cols":"12"}},[_c('common-input',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search user...","clearable":"","height":"30"},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1),_c('v-col',{staticClass:"campaign-settings-panel__users",attrs:{"cols":"12"}},[_vm._l((_vm.filteredSelectedUsers),function(user){return [_c('the-campaign-settings-panel-user',{key:user.id,attrs:{"user":user},on:{"remove":_vm.removeUser}})]})],2)],1)],1),_c('v-col',{staticClass:"pt-10 campaign-settings-panel__actions",attrs:{"cols":"7"}},[_c('common-button',{staticStyle:{"max-width":"180px"},attrs:{"height":40,"disabled":_vm.disableCtaButton},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v(" Save Changes ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }