<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="600px"
    class="payment-module-other-methods-panel"
  >
    <panel-header :show.sync="show" :step="step" @update:step="handleStepChange">
      {{ panelTitle }}
    </panel-header>

    <cg-loader v-if="loading" />

    <div v-else class="payment-module-other-methods-panel__content-wrapper">
      <other-payment-methods
        v-if="step === 1"
        v-bind="{ ...$props }"
        :paymentMethod.sync="lazyPaymentMethod"
        @onPaymentMethodChange="handlePaymentMethodChange"
      />

      <pay-with-credit-card
        v-else-if="step === 2"
        v-bind="{ ...$props }"
        v-on="$listeners"
        :availableCreditCards="availableCreditCards"
        @update:step="(val) => step = val"
        @onCardSelect="setPaymentMethodAndData"
      />

      <add-new-credit-card
        v-else-if="step === 3"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @update:step="(val) => step = val"
      />

      <pay-with-ach
        v-else-if="step === 4"
        v-bind="{ ...$props }"
        v-on="$listeners"
        :availableAchs="availableAchs"
        @update:step="(val) => step = val"
        @onAchSelect="setPaymentMethodAndData"
      />

      <add-new-ach
        v-else-if="step === 5"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @update:step="(val) => step = val"
      />

      <!-- not used -->
      <pay-with-po
        v-else-if="step === 6"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @onPoSelect="setPaymentMethodAndData"
      />

      <pay-with-bluesnap-card
        v-else-if="step === 7"
        v-bind="{ ...$props }"
        v-on="$listeners"
        :availableCreditCards="availableCreditCards"
        @update:step="(val) => step = val"
        @onCardSelect="setPaymentMethodAndData"
      />

      <add-new-bluesnap-credit-card
        v-else-if="step === 8"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @update:step="(val) => step = val"
      />

      <pay-with-bluesnap-ach
        v-else-if="step === 9"
        v-bind="{ ...$props }"
        v-on="$listeners"
        :availableAchs="availableAchs"
        @update:step="(val) => step = val"
        @onAchSelect="setPaymentMethodAndData"
      />

      <add-new-bluesnap-ach
        v-else-if="step === 10"
        v-bind="{ ...$props }"
        v-on="$listeners"
        @update:step="(val) => step = val"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'
import PaymentType from './utils/PaymentType'

import { CgLoader } from '@corporategift/design-system'
import PanelHeader from '@/components/PanelHeader.vue'
import AddNewBluesnapCreditCard from './panelSteps/AddNewBluesnapCreditCard.vue'
import PayWithBluesnapCard from './panelSteps/PayWithBluesnapCard.vue'
import OtherPaymentMethods from './panelSteps/OtherPaymentMethods.vue'
import PayWithBluesnapAch from './panelSteps/PayWithBluesnapAch.vue'
import PayWithCreditCard from './panelSteps/PayWithCreditCard.vue'
import AddNewBluesnapAch from './panelSteps/AddNewBluesnapAch.vue'
import AddNewCreditCard from './panelSteps/AddNewCreditCard.vue'
import PayWithAch from './panelSteps/PayWithAch.vue'
import PayWithPo from './panelSteps/PayWithPo.vue'
import AddNewAch from './panelSteps/AddNewAch.vue'

export default {
  name: 'ThePaymentModuleOtherPaymentsPanel',
  mixins: [panelVModel],
  components: {
    AddNewBluesnapCreditCard,
    OtherPaymentMethods,
    PayWithBluesnapCard,
    PayWithBluesnapAch,
    AddNewBluesnapAch,
    PayWithCreditCard,
    AddNewCreditCard,
    PanelHeader,
    PayWithAch,
    AddNewAch,
    PayWithPo,
    CgLoader,
  },
  props: {
    paymentMethod: {
      type: String,
      required: false,
      default: PaymentType.BE,
    },
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    userSavedPaymentData: {
      type: Object,
      required: true,
    },
    availableMethods: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    step: 1,
    lazyPaymentMethod: null,
  }),
  computed: {
    panelTitle () {
      const { step } = this;

      if ([2, 7].includes(step)) { return 'Pay with Credit card' }
      if ([3, 8].includes(step)) { return 'Add new Credit card' }
      if ([4, 9].includes(step)) { return 'Pay via ACH' }
      if ([5, 10].includes(step)) { return 'Add new ACH' }
      if ([6].includes(step)) { return 'Pay with PO' }

      return 'Other payment method'
    },
    availableCreditCards () {
      return this.userSavedPaymentData?.creditCards ?? []
    },
    availableAchs () {
      return this.userSavedPaymentData?.achs ?? []
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.lazyPaymentMethod = this.paymentMethod
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
    lazyPaymentMethod: 'handlePaymentMethodChange',
  },
  methods: {
    handleStepChange (val) {
      if (val + 1 === 3) {
        // CC
        this.step = 2
      } else if (val + 1 === 5) {
        // ACH
        this.step = 4
      } else if (val + 1 === 8) {
        // Bluesnap CC
        this.step = 7
      } else if (val + 1 === 10) {
        // Bluesnap ACH
        this.step = 9
      } else {
        this.step = 1
      }
    },
    // TODO refactor and use ternary operators
    handlePaymentMethodChange (val, oldVal) {
      if (oldVal === null || val === null) { return; }

      if (val === PaymentType.BE) {
        this.setPaymentMethodAndData(val, null)
      } else if (val === PaymentType.CC) {
        if (this.availableCreditCards.length) {
          this.step = 2;
        } else {
          this.setPaymentMethodAndData(val, null)
        }
      } else if (val === PaymentType.ACH) {
        if (this.availableAchs.length) {
          this.step = 4;
        } else {
          this.step = 5;
        }
      } else if (val === PaymentType.CC_Bluesnap) {
        if (this.availableCreditCards.length) {
          this.step = 7
        } else {
          this.step = 8
        }
      } else if (val === PaymentType.ACH_Bluesnap) {
        if (this.availableAchs.length) {
          this.step = 9
        } else {
          this.step = 10
        }
      } else {
        this.setPaymentMethodAndData(val, null)
      }

      this.lazyPaymentMethod = val
    },
    setPaymentMethodAndData (method, data) {
      this.$emit('update:paymentMethod', method)
      this.$emit('update:paymentData', data)
      this.show = false
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-module-other-methods-panel {
  &::v-deep > .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > span {
      padding: 20px 0 10px;
      color: #95979D;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      line-height: normal;
      font-family: 'Lato-Bold', sans-serif;
      font-size: 14px;
    }

    & button {
      border-radius: 100px;
    }
  }

  & .cg-loader {
    max-height: 200px;
    margin-block: auto;
  }
}
</style>
