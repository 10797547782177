<template>
  <v-container class="px-9 py-0">
    <v-row no-gutters>
      <v-col cols="12" class="mb-4">
        <unsaved-changes-alert
          class="mx-n9"
          v-if="showRecipientsAlert"
          @saveChanges="() => { recipientAlertNewMethod = null; showRecipientsAlert = false }"
          @closePanel="selectedMethod = recipientAlertNewMethod"
        >
          <template #default>
            Added recipients will be removed - are you sure?
          </template>

          <template #exit>
            Continue
          </template>

          <template #save>
            Stay
          </template>
        </unsaved-changes-alert>
      </v-col>

      <v-col cols="12" v-if="!hideNavigation">
        <v-row class="px-4 py-3 d-flex" justify="end" align="center">
          <v-col
            class="recipients-area__link"
            v-if="selectedMethod !== 'import'"
            @click="changeSelectedMethod('import')"
          >
            <icons-upload-file :width="17" :height="19" color="#9B9B9B" />

            Import recipients
          </v-col>
          <v-col
            class="recipients-area__link"
            v-if="selectedMethod !== 'manually'"
            @click="changeSelectedMethod('manually')"
          >
            <icons-edit :width="15" :height="15" />

            Enter Manually
          </v-col>
          <v-col
            class="recipients-area__link"
            v-if="selectedMethod !== 'addressbook'"
            @click="changeSelectedMethod('addressbook')"
          >
            <icons-contacts />

            Select from Contacts
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="selectedMethod === 'manually'">
        <the-recipients-area-select-manually
          v-bind="_props"
          v-on="$listeners"
          @setUnsavedChanges="(val) => selectAreaHasUnsavedChanges = val"
          @showPreview="reviewEGift"
        />
      </v-col>

      <v-col cols="12" v-if="selectedMethod === 'import'">
        <the-recipients-area-import
          v-bind="_props"
          ref="importRecipients"
          @hideNavigation="(val) => hideNavigation = val"
          @showPreview="reviewEGift"
          @hideCloseButton="(val) => $emit('hideDefaultClose', val)"
        />
      </v-col>

      <v-col cols="12" v-if="selectedMethod === 'addressbook'">
        <the-recipients-address-book
          v-bind="_props"
          ref="addressBook"
          @showPreview="reviewEGift"
          @hideNavigation="(val) => hideNavigation = val"
          @hideCloseButton="(val) => $emit('hideDefaultClose', val)"
          @setUnsavedChanges="(val) => selectAreaHasUnsavedChanges = val"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheRecipientsAreaSelectManually from './recipients/TheRecipientsAreaSelectManually.vue'
import UnsavedChangesAlert from '@/components/myInventory/Panels/common/UnsavedChangesAlert'
import TheRecipientsAreaImport from './recipients/TheRecipientsAreaImport.vue'
import TheRecipientsAddressBook from './recipients/TheRecipientsAddressBook.vue'

import panelType from '../mixins/panelType'
import marketplaceUser from '../mixins/marketplaceUser'

export default {
  name: 'TheRecipientsArea',
  components: {
    TheRecipientsAreaSelectManually,
    TheRecipientsAddressBook,
    TheRecipientsAreaImport,
    UnsavedChangesAlert
  },
  mixins: [
    panelType,
    marketplaceUser,
  ],
  props: {
    value: {
      type: Array,
      required: true
    },
    linkType: {
      type: String,
      required: false,
      default: null
    },
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    },
    methodOfEnteringRecipients: {
      type: String,
      required: false,
      validator: val => {
        return ['manually', 'import', 'addressbook'].includes(val)
      },
      default: 'manually'
    }
  },
  data: () => ({
    hideNavigation: false,
    selectAreaHasUnsavedChanges: false,
    recipientAlertNewMethod: null,
    showRecipientsAlert: false
  }),
  computed: {
    selectedMethod: {
      get () {
        return this.methodOfEnteringRecipients
      },
      set (val) {
        this.$emit('update:methodOfEnteringRecipients', val)
      }
    },
  },
  watch: {
    selectedMethod: function () {
      this.recipientAlertNewMethod = null
      this.showRecipientsAlert = false
      this.selectAreaHasUnsavedChanges = false
    }
  },
  methods: {
    changeSelectedMethod (newMethod) {
      const { selectAreaHasUnsavedChanges } = this

      if (selectAreaHasUnsavedChanges) {
        this.showRecipientsAlert = true
        this.recipientAlertNewMethod = newMethod
      } else {
        this.selectedMethod = newMethod
      }
    },
    backToRecipientMethods (val) {
      this.$refs?.importRecipients?.continueUpload(val)
      this.$refs?.addressBook?.continueReview(val)
    },
    reviewEGift (data) {
      this.$emit('input', data)
      this.$emit('showPreview')
    }
  }
}
</script>

<style lang="scss">
.recipients-area {
  &__link {
    font-family: 'Lato-Regular', sans-serif !important;
    white-space: nowrap;
    display: flex;
    flex: 0 1;
    gap: 8px;
    align-content: center;
    justify-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #219358;
  }

  &__title {
    font-family: 'Lato-Light', sans-serif !important;
    font-size: 30px;
    padding-top: 34px;
    line-height: 36px;
    color: #000;
  }

  &__subtitle {
    font-family: 'Lato-Italic', sans-serif !important;
    font-style: italic;
    font-size: 15px;
    line-height: 18px;
    color: #9F9F9F;
  }
}
</style>
