<template>
  <div class="create-campaign-source-collection">
    <div class="create-campaign-source-collection__title">
      <span>Select gifts from</span>
      <h1>{{ collectionTitle }}</h1>
    </div>

    <cg-loader v-if="loading" />

    <template v-else>
      <v-chip-group show-arrows>
        <cg-chip
          v-for="budget in collectionBudgetOptions"
          :key="budget.key"
          :class="{ 'cg-chip--active': selectedBudgetId === budget.key }"
          @click="selectedBudgetId = budget.key"
        >
          {{ budget.title }}
        </cg-chip>

        <collections-custom-price-range
          :customBudgetOption.sync="customBudgetOption"
          :isSelected="selectedBudgetId === 'custom'"
          @select="selectedBudgetId = 'custom'"
        />
      </v-chip-group>

      <div class="create-campaign__products-wrapper">
        <div class="create-campaign__products-actions">
          <div class="create-campaign__products-filters">
            <common-input
              v-model="search"
              placeholder="Search gifts"
              prepend-inner-icon="mdi-magnify"
              height="36"
              clearable
              style="width: 250px"
              filter-style
            />

            <span
              role="button"
              :tabindex="disableSelectAll ? '-1' : '0'"
              @click="addFilteredProducts"
              @keyup.enter.space="addFilteredProducts"
              :aria-disabled="disableSelectAll ? 'true' : 'false'"
            >
              Select all
            </span>

            <span
              role="button"
              :tabindex="disableClearAll ? '-1' : '0'"
              @click="removeFilteredProducts"
              @keyup.enter.space="removeFilteredProducts"
              :aria-disabled="disableClearAll ? 'true' : 'false'"
            >
              Clear all
            </span>
          </div>

          <cg-button
            :disabled="!selectedProductsIds.length"
            @click="goToNextPage"
          >
            Add selected

            <template #append-icon>
              <div class="create-campaign__button-badge">
                {{ selectedProductsIds.length }}
              </div>
            </template>
          </cg-button>
        </div>

        <div class="create-campaign__products-grid">
          <product-card
            v-for="product in filteredProducts"
            :key="product.product_id"
            :id="product.product_id"
            :is-selected="selectedProductsIds.includes(product.product_id)"
            :image="product.product.image"
            :name="product.product.name"
            :price="product.product.price"
            :url="product.link"
            @addProduct="onAddProduct"
            @removeProduct="onRemoveProduct"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { createNamespacedHelpers } from 'vuex'
import maxGiftsGuard from '@/components/createCampaign/mixins/maxGiftsGuard'

import { CgLoader, CgButton, CgChip } from '@corporategift/design-system'
import ProductCard from '@/components/createCampaign/ProductCard.vue'
import CollectionsCustomPriceRange from '@/components/createCampaign/CreateCampaignCollectionsCustomPriceRange.vue'

const { mapState, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignSourceCollection',
  mixins: [maxGiftsGuard],
  components: {
    CgChip,
    CgLoader,
    CgButton,
    ProductCard,
    CollectionsCustomPriceRange,
  },
  data: () => ({
    loading: false,
    selectedBudgetId: 0,
    themeCollections: [],
    themeName: '',
    search: '',
    selectedProductsIds: [],
    customBudgetOption: {
      from: null,
      to: null,
    },
  }),
  computed: {
    ...mapState(['source']),
    sourceCollections () { return this.source.collections },
    collectionId () { return this.$route.params?.id ?? 0 },
    collectionData () {
      const { sourceCollections, collectionId } = this
      return sourceCollections.find((collection) => collection.id === collectionId) ?? null
    },
    collectionTitle () { return this.collectionData?.name ?? this.themeName },
    collectionBudgetOptions () {
      const collections = structuredClone(this.collectionData?.collections) ?? []

      return collections
        ?.sort((a, b) => a.min_price - b.min_price)
        ?.map(({ id, min_price: min, max_price: max }) => ({
          key: id,
          title: `$${Math.round(min)} - $${Math.round(max)}`
        })) ?? []
    },
    customPriceRangeProducts () {
      const { from, to } = this.customBudgetOption;
      const themeData = structuredClone(this.themeCollections)

      let allProducts = themeData?.reduce((acc, collection) => {
        acc.push(...collection.items)
        return acc
      }, [])

      if (from) { allProducts = allProducts.filter((product) => product.product.price >= from) }
      if (to) { allProducts = allProducts.filter((product) => product.product.price <= to) }
      // return unique products
      return [...new Map(allProducts.map(item => [item.product_id, item])).values()]
    },
    selectedBudgetProducts () {
      const { selectedBudgetId, themeCollections, customPriceRangeProducts } = this

      if (selectedBudgetId === 'custom') { return customPriceRangeProducts }

      const selectedCollection = themeCollections.find((collection) => collection.id === selectedBudgetId)

      return selectedCollection?.items ?? []
    },
    filteredProducts () {
      const { selectedBudgetProducts, search } = this
      const textToSearch = (search ?? '').toLowerCase()

      return selectedBudgetProducts
        .filter((item) => item.product.name.toLowerCase().indexOf(textToSearch) !== -1)
    },
    filteredProductsIds () {
      return this.filteredProducts.map((product) => product.product_id)
    },
    disableSelectAll () {
      const { selectedProductsIds, filteredProductsIds } = this
      return filteredProductsIds.every((id) => selectedProductsIds.includes(id))
    },
    disableClearAll () {
      const { selectedProductsIds, filteredProductsIds } = this
      return !filteredProductsIds.some((id) => selectedProductsIds.includes(id))
    },
  },
  watch: {
    collectionTitle: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.setBreadcrumbs([
            {
              routeName: 'CreateCampaignSource',
              title: 'Select gifts',
            },
            {
              routeName: 'CreateCampaignSourceCollections',
              title: 'Our collections',
            },
            {
              routeName: 'CreateCampaignSourceCollection',
              title: val,
            }
          ])
        }
      }
    },
    selectedBudgetId: function () {
      this.selectedProductsIds = []
    }
  },
  created () {
    const { collectionId } = this
    this.loading = true

    Api.get(`/shared/collections/themes/${collectionId}`)
      .then(({ data }) => {
        this.themeCollections = data.collections
        this.themeName = data.name
        this.selectedBudgetId = data.default_collection_id ?? data.collections?.[0]?.id ?? 0
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    ...mapMutations([
      'setBreadcrumbs',
      'addNewSelectedProducts',
    ]),
    addFilteredProducts () {
      const { filteredProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = [...new Set([...selectedProductsIds, ...filteredProductsIds])]
    },
    removeFilteredProducts () {
      const { filteredProductsIds, selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => !filteredProductsIds.includes(id))
    },
    onAddProduct (productId) {
      this.selectedProductsIds.push(productId)
    },
    onRemoveProduct (productId) {
      const { selectedProductsIds } = this
      this.selectedProductsIds = selectedProductsIds.filter((id) => id !== productId)
    },
    goToNextPage () {
      const { selectedProductsIds, selectedBudgetProducts, collectionId } = this
      const ids = new Set(selectedProductsIds)

      const selectedProducts = selectedBudgetProducts
        .filter((product) => ids.has(product.product_id))
        .map((product) => ({
          id: product.product_id,
          sourceId: Number(collectionId),
          isInventory: false,
          isCollection: true,
          image: product.product.image,
          name: product.product.name,
          price: product.product.price,
          url: product.link,
        }))

      if (!this.maxGiftsGuard(selectedProducts.length)) { return }

      this.addNewSelectedProducts(selectedProducts)
      this.$router.push({ name: 'CreateCampaignSourceReview', params: this.$route.params })
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-collection {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 42px;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      font-family: 'Lato-Bold', sans-serif;
      font-weight: 700;
      line-height: 37px;
      font-size: 24px;
      text-align: center;
      color: #222325;
    }
  }

  &::v-deep {
    & .v-slide-group__content {
      gap: 6px 10px;
    }

    & .v-slide-group__prev--disabled,
    & .v-slide-group__next--disabled {
      display: none;
    }
  }

  &::v-deep .cg-chip {
    & .cg-chip__content {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
    }

    &--active {
      --chip-color: #007E76 !important;
      --chip-text-color: #fff !important;
      --chip-hover-color: #007E76 !important;
    }
  }
}
</style>
