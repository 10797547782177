var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form"},[_c('label',{attrs:{"for":"full-name"}},[_vm._v(" Full name ")]),_c('div',{staticClass:"d-flex",attrs:{"id":"full-name"}},[_c('common-input',{attrs:{"placeholder":"First name","rules":[_vm.validation.required],"autocomplete":"given-name"},model:{value:(_vm.formFirstName),callback:function ($$v) {_vm.formFirstName=$$v},expression:"formFirstName"}}),_c('common-input',{attrs:{"placeholder":"Last name","rules":[_vm.validation.required],"autocomplete":"family-name"},model:{value:(_vm.formLastName),callback:function ($$v) {_vm.formLastName=$$v},expression:"formLastName"}})],1),_c('div',{staticClass:"d-flex align-center pt-7 mb-1 g10"},[_c('label',{staticClass:"mb-0",attrs:{"for":"display-name"}},[_vm._v(" Display name ")]),_c('popper',{attrs:{"options":{
        placement: 'top',
        modifiers: {
          offset: {
            offset: '0,5px'
          },
        }
      }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"max-width":"400px"}},[_vm._v(" Display Name can be used as gift giver name in the E Gift orders in case the account name is not the desired display name. For example an E Gift is sent from a whole company rather than the account holder name ")]),_c('div',{staticClass:"d-flex",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"16","height":"16"}})],1)])],1),_c('common-input',{attrs:{"id":"display-name","placeholder":"Display name"},model:{value:(_vm.formDisplayName),callback:function ($$v) {_vm.formDisplayName=$$v},expression:"formDisplayName"}}),_c('label',{attrs:{"for":"email"}},[_vm._v(" Email address ")]),_c('common-input',{attrs:{"id":"email","placeholder":"Email","rules":[_vm.validation.required, _vm.validation.email],"type":"email"},model:{value:(_vm.formEmail),callback:function ($$v) {_vm.formEmail=$$v},expression:"formEmail"}}),_c('cg-phone-input',{staticStyle:{"padding-top":"30px"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phone number ")]},proxy:true}]),model:{value:(_vm.formPhoneNumber),callback:function ($$v) {_vm.formPhoneNumber=$$v},expression:"formPhoneNumber"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }