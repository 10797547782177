<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="est-cost-panel"
  >
    <close-panel-button
      @close="() => show = false"
      text="Close"
    />

    <v-container class="px-9 py-0" v-if="gift && show">
      <v-row class="flex-column">
        <v-col cols="12" class="est-cost-panel__title">
          Estimate eGift campaign cost
        </v-col>
        <v-col cols="12" class="est-cost-panel__subtitle">
          Estimate your total cost based on the number of eGift recipients, times the average product cost in your eGift bundle
        </v-col>

        <v-col cols="5">
          <label for="recipients">Number of recipients</label>
          <common-input
            v-model.number="recipients"
            @input="recipientsHandler"
            type="number"
            :min="0"
            :step="1"
            id="recipients"
            name="recipients"
            class="pt-1"
          />
        </v-col>

        <v-col cols="5">
          <v-row dense class="calculated-prices">
            <common-loader v-if="loading" style="padding: 30px 0" />

            <template v-else>
              <!-- Recipients -->
              <v-col cols="6">Recipients</v-col>
              <v-col cols="6"> {{ recipients || 0 }} </v-col>
              <!-- Price -->
              <v-col cols="6">Max gift cost</v-col>
              <v-col cols="6">{{ giftMaxCost | priceFilter }}</v-col>
              <!-- Shipping cost -->
              <template v-if="giftMaxShipping > giftCardFeeTotal">
                <v-col cols="6">Max shipping</v-col>
                <v-col cols="6">{{ giftMaxShipping | priceFilter }}</v-col>
              </template>
              <!-- Tax -->
              <template v-if="giftSalesTax">
                <v-col cols="6">Max sales tax</v-col>
                <v-col cols="6">{{ giftSalesTax | priceFilter }}</v-col>
              </template>
              <!-- eGift Fee -->
              <template v-if="giftCardFeeTotal && giftCardFeeTotal > giftMaxShipping">
                <v-col cols="6">{{ egiftFeeLabel }}</v-col>
                <v-col cols="6">{{ egiftFeeTotal | priceFilter }}</v-col>
              </template>
              <!-- Dropship fee -->
              <template v-if="dropshipFeeTotal">
                <!-- total -->
                <v-col cols="6">Max Dropship fee</v-col>
                <v-col cols="6">{{ dropshipFeeTotal| priceFilter }}</v-col>
              </template>
              <!-- Gift Card Fee -->
              <template v-if="giftCardFeeTotal">
                <v-col cols="6">Gift card fee</v-col>
                <v-col cols="6">{{ giftCardFeeTotal | priceFilter }}</v-col>
              </template>
              <!-- EST Cost -->
              <v-col cols="6">Est. cost</v-col>
              <v-col cols="6">{{ giftTotalCost | priceFilter }}</v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'

import panelVModel from '@/mixins/panelVModel'
import gift from './mixins/gift'

export default {
  name: 'TheEstCostPanel',
  mixins: [
    panelVModel,
    gift
  ],
  data: () => ({
    loading: false,
    abortController: null,
    recipients: 1,
    campaignCosts: {
      fee: 0,
      fee_per_unit: 0,
      fee_rate: 0,
      fee_type: 'quantity',
      gift_card_fee: 0,
      gift_card_fee_per_unit: 0,
      gift_card_fee_rate: 0,
      shipping: 0,
      shipping_per_unit: 0,
      subtotal: 0,
      subtotal_per_unit: 0,
      dropship_fee: 0,
      dropship_fee_per_unit: 0,
      tax: 0,
      tax_rate: 0,
      total: 0,
    },
  }),
  computed: {
    egiftFeeLabel () {
      const { fee_rate, fee_type } = this.campaignCosts
      return fee_type === 'percent' ? `${fee_rate}% eGift fee` : 'eGift fee'
    },
    giftMaxCost () {
      return this.campaignCosts?.subtotal ?? 0
    },
    giftMaxShipping () {
      return this.campaignCosts?.shipping ?? 0
    },
    giftSalesTax () {
      return this.campaignCosts?.tax ?? 0
    },
    dropshipFeeTotal () {
      return this.campaignCosts?.dropship_fee ?? 0
    },
    egiftFeeTotal () {
      return this.campaignCosts?.fee ?? 0
    },
    giftCardFeeTotal () {
      return this.campaignCosts?.gift_card_fee ?? 0
    },
    giftTotalCost () {
      return this.campaignCosts?.total ?? 0
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.calculatePrices()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
    recipients: 'handleRecipientsChange'
  },
  methods: {
    recipientsHandler () {
      if (this.recipients < 0) this.$nextTick(() => { this.recipients = 0 })
    },
    calculatePrices () {
      const {
        recipients,
        gift: { entity_id: id },
        abortController,
      } = this

      if (abortController) {
        abortController?.abort()        
        this.abortController = null
      }

      this.loading = true
      this.abortController = new AbortController();

      Api.get(`campaigns/send-egift/${id}/calculate`, {
        signal: this.abortController.signal,
        params: { number_of_recipients: recipients || 1 }
      })
        .then((costs) => {
          this.campaignCosts = costs
          this.loading = false
        })
        .catch((e) => {
          if (e?.message !== 'canceled') {
            this.loading = false
          }
        })
        .finally(() => (this.abortController = null))
    },
    handleRecipientsChange: debounce(function () { this.calculatePrices() }, 400)
  }
}
</script>

<style lang="scss" scoped>
.est-cost-panel {
  &__title {
    font-family: 'Lato-Regular', sans-serif !important;
    line-height: 44px;
    font-size: 40px;
    color: #000;
  }

  &__subtitle {
    font-family: 'Lato-Italic', sans-serif !important;
    line-height: 18px;
    font-size: 15px;
    color: #979797;
  }

  label {
    font-family: 'Lato-Bold', sans-serif !important;
    line-height: 18px;
    font-size: 15px;
    color: #000;
  }

  .calculated-prices {
    background: #EDFCFB;
    padding: 8px 16px;

    .col {
      font-size: 15px;
      line-height: 24px;

      &:nth-child(odd) {
        font-family: 'Lato-Bold', sans-serif !important;
        color: #222325;
      }

      &:nth-child(even) {
        font-family: 'Lato-Regular', sans-serif !important;
        color: #000;
      }
    }
  }
}
</style>
