<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div class="f15 gray4 d-flex align-center pointer guideTxt" @click="guideDialog = true">
          <span>How to use contacts</span>
          <v-icon color="#000" class="ml-2">mdi-play-circle</v-icon>
        </div>
      </v-col>

      <v-col cols="12" class="page-title text-capitalize">
        Contacts
      </v-col>
      <!-- controls -->
      <v-col cols="12">
        <div
          class="d-flex align-center"
          style="gap: 30px"
        >
          <template v-if="!selectedItems.length">
            <div class="d-flex pointer align-center" @click="createGroupDialog = true">
              <icons-add-group :width="20" :height="20" color="#95979D" />

              <span class="f14 green2 ml-2">New group</span>
            </div>
            <div class="d-flex pointer align-center" @click="importContactsPanel = true">
              <icons-import-file :width="20" :height="16" color="#95979D" />

              <span class="f14 green2 ml-2">Import contacts</span>
            </div>
            <div class="d-flex pointer align-center" @click="showCrateEditPanel(null)">
              <icons-add-contact :width="20" :height="20" color="#95979D" />

              <span class="f14 green2 ml-2">Add contact</span>
            </div>
          </template>

          <template v-else>
            <div class="d-flex pointer align-center" @click="showAddToGroupDialog(selectedItems)" v-if="!isGroupInSelectedItems">
              <icons-add-to-group :width="28" :height="20" color="#95979D" />
              <span class="f14 green2 ml-2">
                Add to group
              </span>
            </div>

            <div class="d-flex pointer align-center" @click="showDeleteDialog(selectedItems)">
              <icons-delete :width="14" :height="17" color="#95979D" />

              <span class="f14 green2 ml-2">Delete contacts/groups</span>
            </div>
          </template>

          <the-table-download-invoice
            :downloadMethod="downloadCSV"
            wrapperStyles="margin-left: auto"
            downloadIconColor="#95979D"
          />
        </div>
      </v-col>

      <v-col cols="12" class="pb-0">
        <the-filter-bar
          :contactsNumber="contactsNumber"
          :groupsNumber="groupsNumber"
          :search.sync="search"
          :showContacts.sync="showContacts"
          :showGroups.sync="showGroups"
          :sortBy.sync="sortBy"
          :select.sync="select"
          @clearSelected="setSelectedContacts([])"
        />
      </v-col>

      <v-col cols="12" v-if="loading">
        <common-loader style="padding: 200px 0" />
      </v-col>

      <v-col cols="12" class="pt-0" v-else>
        <contacts-table
          :items="filteredAddressBook"
          :selected.sync="selectedItems"
          @showAddToGroupPanel="(contact) => showAddToGroupDialog([contact])"
          @showEditPanel="(item) => showCrateEditPanel(item)"
          @showEditGroupPanel="(item) => showEditGroupPanel(item)"
          @showDeletePanel="(item) => showDeleteDialog([item])"
        />
      </v-col>
    </v-row>

    <the-edit-group-panel
      v-model="editGroupPanel"
      :group="selectedGroup"
      :lockPanel="createContactPanel || deleteDialog || importContactsPanel"
      :loading="loading"
      @showEditPanel="(item) => showCrateEditPanel(item)"
      @showDeletePanel="(item) => showDeleteDialog(item)"
      @showImportPanel="() => importContactsPanel = true"
      @updateGroup="updateExistingGroup"
    />

    <the-add-edit-contact-panel
      v-model="createContactPanel"
      :contact="selectedContact"
      :groups="groups"
      :addToGroup="editGroupPanel ? selectedGroup : null"
      @addContact="addNewContact"
      @addGroup="addNewGroup"
      @updateContact="updateExistingContact"
      @fetchData="fetchData()"
    />

    <the-delete-contact-group-modal
      v-model="deleteDialog"
      :contacts="selectedContact"
      :userIsInGroupPanel="editGroupPanel"
      :group="selectedGroup"
      @fetchData="fetchData()"
    />

    <the-add-to-group-modal
      v-model="addToGroupDialog"
      :contacts="selectedContact"
      :groups="groups"
      @fetchData="fetchData()"
    />

    <the-import-contacts-panel
      v-model="importContactsPanel"
      :groups="groups"
      :addToGroup="editGroupPanel ? selectedGroup : null"
      @fetchData="fetchData()"
    />

    <the-add-new-group-modal
      v-model="createGroupDialog"
      @addGroup="addNewGroup"
    />

    <the-address-book-guide-modal
      v-model="guideDialog"
    />
  </v-container>
</template>

<script>
import Api from '@/axios/api'

import TheFilterBar from '@/components/myAddressBook/TheFilterBar'
import ContactsTable from '@/components/myAddressBook/ContactsTable'
import TheAddEditContactPanel from '@/components/myAddressBook/TheAddEditContactPanel'
import TheDeleteContactGroupModal from '@/components/myAddressBook/TheDeleteContactGroupModal'
import TheEditGroupPanel from '@/components/myAddressBook/TheEditGroupPanel'
import TheAddNewGroupModal from '@/components/myAddressBook/TheAddNewGroupModal'
import TheAddressBookGuideModal from '@/components/myAddressBook/TheAddressBookGuideModal'
import TheImportContactsPanel from '@/components/myAddressBook/TheImportContactsPanel'
import TheAddToGroupModal from '@/components/myAddressBook/TheAddToGroupModal'
import TheTableDownloadInvoice from '@/components/myCGCredits/TheTableDownloadInvoice.vue'

import {
  filterContactBySearch,
  sortContactByValue,
  isAddressAnGroup
} from '@/components/myAddressBook/mixins'

export default {
  name: 'my-address-book',
  components: {
    TheFilterBar,
    ContactsTable,
    TheEditGroupPanel,
    TheAddToGroupModal,
    TheAddNewGroupModal,
    TheAddEditContactPanel,
    TheImportContactsPanel,
    TheTableDownloadInvoice,
    TheAddressBookGuideModal,
    TheDeleteContactGroupModal,
  },
  mixins: [
    filterContactBySearch,
    sortContactByValue,
    isAddressAnGroup
  ],
  data: () => ({
    addressContacts: [],
    addressGroups: [],
    selectedItems: [],
    selectedContact: null,
    selectedGroup: null,
    loading: false,
    // panels and dialogs
    createContactPanel: false,
    createGroupDialog: false,
    deleteDialog: false,
    importContactsPanel: false,
    guideDialog: false,
    editGroupPanel: false,
    addToGroupDialog: false,
    // filter-bar
    search: null,
    showContacts: true,
    showGroups: true,
    sortBy: null,
    select: null
  }),
  computed: {
    items () {
      const { addressContacts, addressGroups } = this
      return [...addressContacts, ...addressGroups]
    },
    groups () {
      const groups = this.items?.filter((item) => this.isAddressAnGroup(item))
      return groups.map((item) => ({
        entity_id: item.entity_id,
        name: item.name
      }))
    },
    filteredAddressBook () {
      const { items, showContacts, showGroups, search, sortBy } = this
      const showTypes = []
      if (showContacts) showTypes.push('contact')
      if (showGroups) showTypes.push('group')

      const itemsWithTypes = items.filter((item) => {
        if (showTypes.includes('group') && showTypes.includes('contact')) return true
        if (showTypes.includes('group')) return this.isAddressAnGroup(item)
        if (showTypes.includes('contact')) return !this.isAddressAnGroup(item)
      })

      const itemsAfterSearch = this.filterContactBySearch(itemsWithTypes, search)

      const itemsAfterSort = this.sortContactByValue(itemsAfterSearch, sortBy)

      return itemsAfterSort
    },
    contactsNumber () {
      return this.addressContacts?.length || 0
    },
    groupsNumber () {
      return this.addressGroups?.length || 0
    },
    isGroupInSelectedItems () {
      const { selectedItems } = this
      return selectedItems?.some((item) => this.isAddressAnGroup(item))
    }
  },
  watch: {
    select: function (val) {
      const { filteredAddressBook } = this

      if (val === 'none') {
        this.setSelectedContacts([])
      }

      if (val === 'contacts') {
        this.setSelectedContacts(filteredAddressBook.filter((item) => !this.isAddressAnGroup(item)))
      }

      if (val === 'groups') {
        this.setSelectedContacts(filteredAddressBook.filter((item) => this.isAddressAnGroup(item)))
      }

      if (val === 'all') {
        this.setSelectedContacts(filteredAddressBook)
      }
    },
    showContacts: function (val) {
      if (!val) {
        if (this.select === 'all') this.select = 'groups'
        if (this.select === 'contacts') this.select = 'none'
      }
    },
    showGroups: function (val) {
      if (!val) {
        if (this.select === 'all') this.select = 'contacts'
        if (this.select === 'groups') this.select = 'none'
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      this.select = null
      this.setSelectedContacts([])

      Api.get('/customer/address-book/addresses').then(({ data }) => {
        this.addressContacts = data
        this.loading = false
      })
      Api.get('/customer/address-book/groups').then(({ data }) => {
        this.addressGroups = data
        if (this.selectedGroup) {
          const { entity_id: id } = this.selectedGroup
          const groupIndex = data.findIndex((group) => group.entity_id === id)
          this.selectedGroup = data[groupIndex] || null
        }
        this.loading = false
      })
    },
    setSelectedContact (contact) {
      this.selectedContact = contact
    },
    setSelectedContacts (contacts) {
      this.selectedItems = contacts
    },
    showDeleteDialog (item) {
      this.setSelectedContact(item)
      this.deleteDialog = true
    },
    showEditGroupPanel (item) {
      this.selectedGroup = item
      this.editGroupPanel = true
    },
    showCrateEditPanel (item) {
      this.setSelectedContact(item)
      this.createContactPanel = true
    },
    showAddToGroupDialog (item) {
      this.setSelectedContact(item)
      this.addToGroupDialog = true
    },
    addNewContact (newContact) {
      const { editGroupPanel, selectedGroup } = this
      if (editGroupPanel) selectedGroup.addresses.push(newContact)

      this.addressContacts.push(newContact)
    },
    updateExistingContact (updatedContact) {
      const { editGroupPanel, selectedGroup } = this
      if (editGroupPanel) {
        const indexInGroup = selectedGroup.addresses
          .findIndex((contact) => contact.entity_id === updatedContact.entity_id)
        this.$set(selectedGroup.addresses, indexInGroup, updatedContact)
      }

      const index = this.addressContacts
        .findIndex((contact) => contact.entity_id === updatedContact.entity_id)
      this.$set(this.addressContacts, index, updatedContact)
    },
    addNewGroup (newGroup) {
      this.addressGroups.push(newGroup)
    },
    updateExistingGroup (updatedGroup) {
      const index = this.addressGroups
        .findIndex((contact) => contact.entity_id === updatedGroup.entity_id)
      this.$set(this.addressGroups, index, updatedGroup)
    },
    async downloadCSV () {
      return Api.get('/customer/address-book/addresses/csv')
        .then((response) => {
          this.$store.commit('downloadCSV', response)
        })
    },
  }
}
</script>

<style lang="scss">
.v-dialog button.mdi-close::before {
  font-size: inherit !important;
}
</style>
