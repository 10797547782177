<template>
  <div class="create-campaign-source">
    <h1>Start adding gifts from:</h1>

    <span class="create-campaign-source__subtext">Mix & match from all three</span>

    <create-campaign-source-list />

    <cg-alert type="information" background-color="#F6F5FF" class="mt-3">
      <b>Note:</b> Max 250 gifts per campaign
    </cg-alert>
  </div>
</template>

<script>
import { CgAlert } from '@corporategift/design-system'
import CreateCampaignSourceList from '@/components/createCampaign/CreateCampaignSourceList.vue'

export default {
  name: 'CreateCampaignSourceSelect',
  components: {
    CgAlert,
    CreateCampaignSourceList,
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  &__subtext {
    color: #62646A;
    font-family: 'Lato-Italic', sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    margin-top: -34px;
  }

  &::v-deep > .cg-alert {
    width: 100%;

    & > .cg-alert__content {
      font-family: 'Lato-Regular', sans-serif;
      font-weight: 400;

      & > b {
        font-family: 'Lato-Bold', sans-serif;
        font-weight: 700;
      }
    }
  }
}
</style>
