<template>
  <v-container>
    <v-row class="mx-0 justify-space-between my-4 product-card flex-nowrap" style="background-color: #fbfbfb">
      <div class="d-flex flex-column product-card_product-info ml-7 my-3">
        <h3 class="f14 gray0 lato-bold uppercase mb-4">General info</h3>
        <dl class="d-flex align-center mb-1">
          <dt class="f14 lato-bold font-weight-bold miw70 mr-1">Placed on:</dt>
          <dd class="f14">{{ order.date | date(dateOptions) }}</dd>
        </dl>
        <dl class="d-flex align-center mb-1" v-if="order.status">
          <dt class="f14 lato-bold font-weight-bold miw70 mr-1">Status:</dt>
          <dd class="f14" :class="{ red1: isCanceled(order.status) }">{{ order.status }}</dd>
        </dl>
        <dl class="d-flex align-center mb-1" v-if="order.payment_method">
          <dt class="f14 lato-bold font-weight-bold miw70 mr-1">Paid by:</dt>
          <dd class="f14">{{ order.payment_method }}</dd>
        </dl>
        <dl class="d-flex align-center mb-1" v-if="order.shipping_status">
          <dt class="f14 lato-bold font-weight-bold miw70 mr-1">Shipment:</dt>
          <dd class="f14">{{ order.shipping_status }}</dd>
        </dl>
        <div class="mt-5">
          <popper
            v-if="order.recipient && order.recipient.gift_message"
            :options="{
              placement: 'top',
              modifiers: {
                offset: {
                  offset: '0,10px'
                },
                preventOverflow: {
                  boundariesElement: 'offsetParent'
                }
              }
            }"
          >
            <div class="popper text-left px-4 py-4">
              {{ order.recipient.gift_message }}
            </div>
            <div
              class="mt-auto product-card_product-info_greet-msg d-flex"
              slot="reference"
            >
              <v-img
                src='/images/greetings_small1.svg'
                max-width="15px"
                max-height="20px"
                class="mr-2"
              ></v-img>
              <span class="product-card_product-info_greet-msg_btn">Gift message</span>
            </div>
          </popper>
        </div>
      </div>
      <div class="d-flex flex-column mr-3 my-3 product-card_qty" v-if="order.customer">
        <h3 class="f14 gray0 lato-bold uppercase mb-4">Sender</h3>
        <address-info :address="order.customer" is-custom-phone>
          T: {{ order.customer.telephone | phoneMask('(###) ###-####') }}
        </address-info>
      </div>
      <div class="d-flex flex-column mr-3 my-3 product-card_qty" v-if="Object.keys(shippingAddress).length">
        <h3 class="f14 gray0 lato-bold uppercase mb-4">Ship to</h3>
        <address-info :address="shippingAddress" is-custom-phone>
          T: {{ shippingAddress.telephone | phoneMask('(###) ###-####') }}
        </address-info>
      </div>
      <div class="d-flex flex-column ml-3 pt-5 pb-4 pl-6 pr-6 cost-information bl1" style="background-color: #fff">
        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.subtotal">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">sub total</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ order.subtotal | priceFilter }}</p>
        </div>
        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.shipping || order.shipping === 0">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">shipping & handling</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ order.shipping | priceFilter }}</p>
        </div>
        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.setup">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">Customization setup</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ order.setup | priceFilter }}</p>
        </div>
        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.tax || order.tax === 0">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">tax</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ order.tax | priceFilter }}</p>
        </div>

        <div
          class="d-flex align-start justify-space-between full-width mb-3"
          v-for="(fee, index) in fees"
          :key="index"
        >
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">{{ fee.name }}</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ fee.amount | priceFilter }}</p>
        </div>

        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.shipping_surcharge">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">Shipping Surcharges</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0">{{ order.shipping_surcharge | priceFilter }}</p>
        </div>
        <div class="d-flex align-start justify-space-between full-width mb-3" v-if="order.discount_amount">
          <div class="order-info">
            <dl class="d-flex align-center justify-space-between">
              <dt class="f14 lato-bold font-weight-bold miw70 uppercase">Discount</dt>
              <dd class="f14"></dd>
            </dl>
          </div>
          <p class="f14 lato-bold ma-0" :class="{ red1: order.discount_amount < 0 }">
            {{ order.discount_amount | priceFilter }}
          </p>
        </div>
        <dl class="d-flex align-center justify-space-between full-width pt-2 mt-auto bt-large">
          <dt class="f16 lato-bold font-weight-bold miw70 uppercase">Total</dt>
          <dd class="f18 lato-bold"> {{ order.total | priceFilter }} </dd>
        </dl>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Popper from 'vue-popperjs'
import AddressInfo from '@/components/myOrders/AddressInfo'

export default {
  name: 'GeneralInfo',
  components: {
    AddressInfo,
    popper: Popper
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data: () => ({
    dateOptions: {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
    shippingAddress: {}
  }),
  computed: {
    fees () { return this.order?.fees ?? [] },
  },
  created () {
    this.shippingAddress = this.order.recipient?.shipping_address && Object.keys(this.order.recipient?.shipping_address).length
      ? this.order.recipient?.shipping_address : {}
  },
  methods: {
    isCanceled (status) {
      return status.toLowerCase() === 'canceled'
    }
  }
}
</script>

<style lang="scss">
</style>
