<template>
  <div>
    <v-data-table
      class="address-table"
      :headers="headers"
      :items="recipients"
      single-expand
      :expanded.sync="expanded"
      item-key="number"
      show-expand
      hide-default-footer
      @click:row="rowClickHandler"
      :items-per-page="recipients.length"
    >
      <template #item.address="{ item }">
        <address-info
          v-if="item.shipping_address"
          :address="item.shipping_address"
          :is-display-name="false"
          is-custom-phone
        >
          T: {{ item.shipping_address.telephone }}
        </address-info>
      </template>
      <template #item.date="{ item }">
        {{ item.date | date }}
      </template>
      <template #item.control="{ item }">
        <div class="d-flex mt-n1">
          <div v-if="item.gift_message" class="address-table__gift-message">
            <v-img
              src="/images/greetings_small1.svg"
              class="d-flex"
              style="opacity: 0.6"
              max-width="15px"
              max-height="20px"
              v-tooltip="{
                content: item.gift_message.replaceAll('\n', '<br />'),
                trigger: 'hover',
                placement: 'top',
                html: true,
                offset: 8,
                classes: 'address-table__tooltip',
              }"
            />

            <span>Greeting message</span>
          </div>

          <!-- ORDER MULTIPLE ITEMS TRACK -->
          <v-btn v-if="item.track_url" class="icons" icon text rounded @click="showTracking(item)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 20">
              <defs/>
              <defs>
                <path id="a" d="M0 .126h14.674v10.611H0z"/>
              </defs>
              <g fill="none" fill-rule="evenodd" opacity=".5">
                <path fill="#000"
                      d="M9.747 4.442c0-.568.464-1.031 1.032-1.031.568 0 1.032.463 1.032 1.031 0 .569-.464 1.032-1.032 1.032a1.02 1.02 0 01-1.032-1.032m3.074 0a2.067 2.067 0 00-2.063-2.063 2.066 2.066 0 00-2.063 2.063c0 1.137.926 2.063 2.063 2.063a2.067 2.067 0 002.063-2.063"/>
                <path fill="#000"
                      d="M10.758 1.032a3.423 3.423 0 013.41 3.41c0 1.032-1.115 3.558-3.073 6.947-.106.19-.274.19-.337.19a.4.4 0 01-.337-.19C8.463 8.021 7.347 5.474 7.347 4.442a3.423 3.423 0 013.41-3.41m0 11.579c.527 0 .99-.274 1.243-.716 1.453-2.527 3.2-5.874 3.2-7.453a4.443 4.443 0 00-8.884 0c0 1.58 1.747 4.926 3.2 7.453.273.442.737.716 1.242.716"/>
                <g transform="translate(0 9.242)">
                  <mask id="b" fill="#fff">
                    <use xlink:href="#a"/>
                  </mask>
                  <path fill="#000"
                        d="M11.768 4.926H2.905a1.878 1.878 0 01-1.873-1.873c0-1.032.842-1.874 1.873-1.874h2.78A.521.521 0 006.21.653a.521.521 0 00-.526-.527H2.905A2.911 2.911 0 000 3.032c0 1.6 1.305 2.905 2.905 2.905h8.863c1.032 0 1.874.842 1.874 1.874a1.878 1.878 0 01-1.874 1.873H4.505a.521.521 0 00-.526.527c0 .294.231.526.526.526h7.263c1.6 0 2.906-1.305 2.906-2.905 0-1.6-1.305-2.906-2.906-2.906"
                        mask="url(#b)"/>
                </g>
              </g>
            </svg>
          </v-btn>
        </div>
      </template>
      <template #expanded-item="{ headers, item }">
        <td class="expand-item px-4" :colspan="headers.length">
          <div
            class="expand-item__wrapper d-flex justify-space-between"
            v-for="(el, i) in item.products"
            :key="i"
          >
            <div class="expand-item__header d-flex">
              <a
                class="expand-item__image mr-4"
                :href="el.url || null"
                target="_blank"
              >
                <img :src="el.image_url" :alt="el.title">
              </a>
              <div class="expand-item__titles">
                <h3 class="f14 lato-bold">
                  <a
                    :href="el.url || null"
                    target="_blank"
                    v-html="el.name"
                  />
                </h3>
                <span class="f12">SKU: #{{ el.sku }}</span>
                <popper
                  v-if="el.custom_attributes"
                  :options="{
                  placement: 'top',
                  modifiers: {
                    offset: {
                      offset: '0,10px'
                    },
                    preventOverflow: {
                      boundariesElement: 'offsetParent'
                    }
                  }
                }">
                  <div class="popper text-left px-4 py-4">
                    <dl class="d-flex align-center justify-space-between" v-for="(custom, i) in el.custom_attributes"
                        :key="i">
                      <dt class="lato-bold f14 mr-1">{{ custom.name }}:</dt>
                      <dd class="f14">{{ custom.value }}</dd>
                    </dl>
                  </div>
                  <div
                    class="mt-auto product-card_product-info_greet-msg d-flex"
                    slot="reference"
                  >
                    <span class="green1 f14">Custom attributes ({{ el.custom_attributes.length }})</span>
                  </div>
                </popper>
              </div>
            </div>
            <div class="expand-item__info">
              <dl class="expand-item__list d-flex justify-space-between">
                <dt class="lato-bold f14">Price:</dt>
                <dd class="f14">{{
                    el.price | priceFilter({
                      style: 'currency',
                      currency: 'USD'
                    })
                  }}
                </dd>
              </dl>
              <dl class="expand-item__list d-flex justify-space-between">
                <dt class="lato-bold f14">Quantity:</dt>
                <dd class="f14">{{ el.quantity }}</dd>
              </dl>
              <dl class="expand-item__list d-flex justify-space-between">
                <dt class="lato-bold f14">Tax:</dt>
                <dd class="f14">{{
                    el.tax | priceFilter({
                      style: 'currency',
                      currency: 'USD'
                    })
                  }}
                </dd>
              </dl>
              <dl v-if="item.shipping" class="expand-item__list d-flex justify-space-between">
                <dt class="lato-bold f14">Shipping:</dt>
                <dd class="f14">{{ item.shipping | priceFilter }}</dd>
              </dl>
              <dl class="expand-item__list d-flex justify-space-between">
                <dt class="lato-bold f14">Total:</dt>
                <dd class="f14">{{
                    el.total | priceFilter({
                      style: 'currency',
                      currency: 'USD'
                    })
                  }}
                </dd>
              </dl>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model='drawer'
      app
      temporary
      right
      width='753px'
      class='track-order-panel'
      v-if="this.selected !== undefined"
    >
      <v-list-item
        @click.stop='drawer = !drawer'
        class='pl-10 track-order-panel_close-btn'
      >
        <v-list-item-avatar>
          <v-icon>mdi-chevron-left</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Close</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-row class='mx-0 pl-13 pt-4 track-order-panel_title'>
        Track order to {{ this.selected.name }}
      </v-row>
      <div class="mt-6 ml-13 track-order-panel_main" v-if="this.selected.track_url !== null">
        <iframe
          :src="this.selected.track_url"
          width="645" height="878"
          style="border: 2px solid #eee"/>
      </div>
    </v-navigation-drawer>

    <multiple-tracking-panel
      v-model="showMultipleTrackingPanel"
      :trackingData="multipleTrackingData"
    />
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import AddressInfo from '@/components/myOrders/AddressInfo'
import MultipleTrackingPanel from '@/components/myEgift/MultipleTrackingPanel'

export default {
  name: 'ExpandInfo',
  components: {
    AddressInfo,
    popper: Popper,
    MultipleTrackingPanel
  },
  props: {
    recipients: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isShow: false,
    headers: [
      {
        text: 'ORDER #',
        align: 'start',
        value: 'number',
        sortable: false
      },
      {
        text: 'STATUS',
        align: 'start',
        value: 'status',
        sortable: false
      },
      {
        text: 'RECIPIENT',
        align: 'start',
        value: 'recipient',
        sortable: false
      },
      {
        text: 'SHIPPING ADDRESS',
        align: 'start',
        value: 'address',
        sortable: false
      },
      {
        text: 'ARRIVAL DATE',
        align: 'start',
        value: 'date',
        sortable: false
      },
      {
        text: '',
        value: 'control',
        width: '75px',
        sortable: false
      },
      {
        text: '',
        value: 'data-table-expand',
        width: '55px',
        sortable: false
      }
    ],
    expanded: [],
    drawer: false,
    selected: undefined,
    showMultipleTrackingPanel: false,
    multipleTrackingData: []
  }),
  methods: {
    rowClickHandler (val) {
      if (val.products && val.products.length) {
        if (!this.expanded.find(ex => ex.id === val.id)) {
          this.expanded.push(val)
        } else {
          this.expanded = this.expanded.filter(exp => exp.id !== val.id)
        }
      }
    },
    trackOrder (item) {
      this.selected = item
    },
    showTracking (item) {
      const { track_url: trackUrl, shipping_data: shippingData } = item
      if (!shippingData || shippingData.length === 1) {
        window.open(trackUrl, '_blank')
      } else {
        this.showMultipleTrackingPanel = true
        this.multipleTrackingData = shippingData
      }
    }
  }
}
</script>

<style lang="scss">
.height0 {
  display: none;
  height: 0;
}

.address-table {
  .v-data-table__wrapper {
    overflow-x: hidden;
  }

  & > .v-data-table__wrapper > table {
    & > thead > tr > th {
      height: 43px;
    }

    & > tbody > tr {
      & > td {
        height: 130px;
        padding: 15px;
        vertical-align: top;
      }

      &:nth-child(even) {
        background: #fff;
      }

      &:nth-child(odd) {
        background: #fbfbfb;
      }
    }

    & > thead > tr:last-child > th {
      border-right: 0;
    }
  }

  &.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: 0 0 0 transparent;
  }

  .v-data-table__expanded {
    &:hover {
      background: #fff !important;

      td {
        &:first-child {
          background-color: #fff !important;
        }
      }
    }
  }

  .icons {
    svg {
      width: 15px;
    }
  }
}

.expand-item {
  cursor: default;

  &__wrapper {
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e4e4e4;
    }
  }

  &__image {
    width: 110px;
    height: 110px;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      margin: auto;
    }
  }

  &__list {
    min-width: 150px;
  }
}

.address-table__gift-message {
  width: 36px;
  height: 36px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .v-image {
    opacity: 0.6;
  }

  & > span {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px 4px;
    border-radius: 2px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    width: 130px;
    border: 1px solid #B3CAC9;
    background-color: #D2F5F3;
  }

  &:hover > span {
    display: block;
  }
}

.address-table__tooltip {
  padding: 14px;
  background-color: #fff;
  border-radius: 8px;
  font-family: 'Lato-Regular', sans-serif;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #16181F;
  max-width: 260px;

  & > .tooltip-arrow {
    bottom: -5px;
    width: 10px;
    height: 10px;
    position: absolute;
    left: calc(50% - 5px);
    transform: rotate(45deg);
    background: inherit;
  }
}
</style>
