import axios from 'axios'
import getCookie from '@/utils/getCookie'
import setCookie from '@/utils/setCookie'

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api',
  headers: {
    Accept: 'application/json'
  }
})

/**
 * Use request interceptor to fetch token.
 * Token is fetched asynchronously and attached to request as Authorization header.
 */
Api.interceptors.request.use(
  async config => {
    config.headers.Authorization = 'Bearer ' + getCookie('cgToken')
    return config
  },
  error => Promise.reject(error)
)

/**
 * Use response interceptor to catch 401 (Unauthorized) api response.
 * If caught, refresh the token and retry the request.
 */
Api.interceptors.response.use(
  response => response.data,

  async error => {
    const { config: request, response } = error

    if (response?.status === 401 && !request?.retryRequest) {
      request.retryRequest = true

      setCookie(null, 0, 'cgToken')
      setCookie(null, 0, 'cgUserIsVendor')
      setTimeout(() => {
        window.location.href = `${process.env.VUE_APP_MAGENTO_URL}/login`
      }, 0)
    }

    throw error
  }
)

export default Api
