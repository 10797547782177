<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" class="page-title text-capitalize">
        My CG Credits
      </v-col>
      <!-- buttons -->
      <v-col cols="12" class="d-flex align-center justify-end g20">
        <div
          v-if="hasMultipleBudgetEntities"
          class="d-flex align-center pointer"
          @click="showAllocatePanel = true"
        >
          <v-img src="/images/multi-arrow-icon.svg" max-width="22" height="14" />
          <span class="f14 green2 ml-2">Reallocate credits</span>
        </div>
        <div
          v-if="userIsCgElite"
          class="d-flex pointer"
          @click="showEditBudgetEntityPanel(null)"
        >
          <icons-add-budget height="18" width="18" />
          <span class="f14 green2 ml-2"> Add budget entity </span>
        </div>
        <div class="d-flex pointer" @click="showCreditsPanel = true">
          <icons-add-funds height="18" width="18" />
          <span class="f14 green2 ml-2">Add credits</span>
        </div>
      </v-col>

      <v-col cols="12" class="pt-0 pb-16">
        <the-credits-info
          :summary="summary"
          :loading="loading"
          :budgetEntities="budgetEntities"
          :marketplaceUser="!userIsCgElite"
        />
      </v-col>

      <!-- tabs -->
      <v-col cols="12">
        <the-credits-tabs
          v-model="tab"
          :marketplaceUser="!userIsCgElite"
        />
      </v-col>
      <!-- tabs items -->
      <v-col cols="12">
        <the-credits-tabs-items
          v-model="tab"
          :budgetEntities="budgetEntities"
          :summary="summary"
          :loading="loading"
          :marketplaceUser="!userIsCgElite"
          @showEditBudgetEntityPanel="showEditBudgetEntityPanel"
          @autoReloadEntity="showAutoReloadEntity"
        />
      </v-col>
    </v-row>

    <allocate-panel
      v-model="showAllocatePanel"
      :budgetEntities="budgetEntities"
      :loading="loading"
      :is-bought-credits="isBoughtCredits"
      @fetchNewData="fetchData()"
      @bought="isBoughtCredits = false"
    />

    <buy-new-credits-panel
      v-model="showCreditsPanel"
      :budgetEntities="budgetEntities"
      :summary="summary"
      :marketplaceUser="!userIsCgElite"
      @allocate="showAllocatePanel = true"
      @bought="isBoughtCredits = true"
      @updateAmount="(amount) => updateAmount(amount)"
    />

    <add-edit-budget-entity
      v-model="showAddEditEntityPanel"
      :selectedEntity="selectedBudgetEntity"
      :budgetEntities="budgetEntities"
      @fetchNewData="fetchData()"
    />

    <the-auto-reload-panel
      v-model="showManageAutoReloadPanel"
      :selectedEntity="selectedBudgetEntity"
      @fetchNewData="fetchData()"
    />
  </v-container>
</template>

<script>
import Api from '@/axios/api'

import TheCreditsTabs from '@/components/myCGCredits/TheCreditsTabs'
import TheCreditsTabsItems from '@/components/myCGCredits/TheCreditsTabsItems'
import TheCreditsInfo from '@/components/myCGCredits/TheCreditsInfo'
import AddEditBudgetEntity from '@/components/myCGCredits/budgetEntities/AddEditBudgetEntity'
import AllocatePanel from '@/components/myCGCredits/budgetEntities/AllocatePanel'
import TheAutoReloadPanel from '@/components/myCGCredits/budgetEntities/TheAutoReloadPanel.vue'
import BuyNewCreditsPanel from '@/components/myCGCredits/credits/TheBuyNewCreditsPanel.vue'

import userIsCgElite from '@/mixins/userIsCgElite'
import userIsLoggedIn from '@/mixins/userIsLoggedIn'

export default {
  name: 'my-cg-credits',
  components: {
    AddEditBudgetEntity,
    TheCreditsTabsItems,
    BuyNewCreditsPanel,
    TheAutoReloadPanel,
    TheCreditsTabs,
    TheCreditsInfo,
    AllocatePanel,
  },
  mixins: [
    userIsCgElite,
    userIsLoggedIn,
  ],
  data: () => ({
    tab: 0,
    loading: false,
    budgetEntities: [],
    selectedBudgetEntity: null,
    summary: {
      balance: null,
      on_hold: null
    },
    // panels
    showAllocatePanel: false,
    showAddEditEntityPanel: false,
    showCreditsPanel: false,
    showManageAutoReloadPanel: false,
    isBoughtCredits: false
  }),
  computed: {
    hasMultipleBudgetEntities () {
      return this.budgetEntities.length > 1
    },
    routeQuery () {
      return this.$route.query ?? {}
    },
  },
  watch: {
    showAddEditEntityPanel (val) {
      if (!val) this.setSelectedEntity(null)
    },
    showManageAutoReloadPanel (val) {
      if (!val) {
        setTimeout(() => { this.setSelectedEntity(null) }, 100)
        // clear router query
        this.$router.replace({}).catch(() => {})
      }
    },
    routeQuery: {
      immediate: true,
      deep: true,
      handler: 'handlePageQueryString'
    },
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      Api.get('/credit/budget-entities')
        .then(({ data, summary }) => {
          this.budgetEntities = data
          this.summary = summary
        })
        .catch(() => (this.$cgToast.error('An error occurred, please contact our support')))
        .then(() => { this.handlePageQueryString() })
        .finally(() => (this.loading = false))
    },
    handlePageQueryString () {
      this.$nextTick(() => {
        const { routeQuery, budgetEntities } = this
        if (routeQuery?.auto_reload) {
          const budgetID = Number(routeQuery?.auto_reload) || null
          const selectedEntity = budgetEntities.find(({ id }) => budgetID === id)

          if (selectedEntity) { this.showAutoReloadEntity(selectedEntity) }
        }
      });
    },
    updateAmount (amount) {
      this.summary.balance += Number(amount)
      this.budgetEntities[0].balance += Number(amount)
    },
    setSelectedEntity (entity) {
      this.selectedBudgetEntity = entity
    },
    showEditBudgetEntityPanel (entity = null) {
      this.showAddEditEntityPanel = true
      this.setSelectedEntity(entity)
    },
    showAutoReloadEntity (entity) {
      this.showManageAutoReloadPanel = true
      this.setSelectedEntity(entity)
    }
  }
}
</script>
