<template>
  <v-navigation-drawer
    class="my-users-edit-multiple-panel"
    v-lock-scroll="show"
    v-model="show"
    width="650px"
    temporary
    right
    app
  >
    <div class="my-users-edit-multiple-panel__title">
      <div>
        Edit options for <b>{{ numberOfSelectedUsers }}</b> users
      </div>

      <div class="my-users-edit-multiple-panel__title-icon" @click="show = false">
        <icons-close color="#62646A" :width="17" :height="17" />
      </div>
    </div>

    <v-form ref="form" class="my-users-edit-multiple-panel__content">
      <template v-if="!loading">
        <my-users-set-spend-limit
          :show.sync="form.setSpendLimit"
          :limit.sync="form.spendLimit.limit"
          :time.sync="form.spendLimit.time"
          :timeList="options.spendLimitTimeFrames"
        />

        <div class="my-users-edit-multiple-panel__item-wrapper">
          <common-checkbox v-model="form.userOrdersRequireApproval" bold-text>
            User orders require approval

            <common-tooltip
              top
              :max-width="250"
              :z-index="8"
              :activator-size="20"
              activator-color="#62646A"
            >
              {{ getTooltipTextBySlug('users_order_approver') }}
            </common-tooltip>
          </common-checkbox>

          <v-expand-transition>
            <common-select
              v-if="form.userOrdersRequireApproval"
              v-model="form.orderApprover"
              :items="availableApprovers"
              placeholder="Select approver"
              :rules="[validation.approverRequired]"
              :height="40"
              item-text="name"
              item-value="approver_id"
              id="orders-approval"
            />
          </v-expand-transition>
        </div>

        <div class="my-users-edit-multiple-panel__item-wrapper">
          <label for="budget-entities">Budget entites</label>

          <select-multiple-entities
            id="budget-entities"
            v-model="form.selectedBudgetEntities"
            :items="options.budgetEntitiesList"
            placeholder="Select budget entities"
            item-text="name"
            item-value="id"
            :height="40"
            clearable
            chips
          />
        </div>

        <div class="my-users-edit-multiple-panel__item-wrapper">
          <label for="categories-access">Set access to categories</label>

          <select-multiple-entities
            id="categories-access"
            v-model="form.selectedCategories"
            :items="options.categoriesList"
            placeholder="Select categories"
            item-text="title"
            item-value="value"
            :height="40"
            clearable
            chips
          />
        </div>

        <div class="my-users-edit-multiple-panel__item-wrapper">
          <label for="campaigns-access">Set access to campaigns</label>

          <select-multiple-entities
            id="campaigns-access"
            v-model="form.selectedCampaigns"
            :items="options.campaignsList"
            placeholder="Select campaigns"
            item-text="name"
            item-value="id"
            :height="40"
            clearable
            chips
          />
        </div>

        <div class="my-users-edit-multiple-panel__item-wrapper">
          <label for="categories-access">Set access to inventory items</label>

          <select-multiple-entities
            id="categories-access"
            v-model="form.selectedInventoryItems"
            :items="options.inventoryItemsList"
            placeholder="Select inventory items"
            item-text="name"
            item-value="id"
            :height="40"
            clearable
            chips
          />
        </div>

        <common-button :height="44" style="max-width: 170px" @click="updateMultipleUsers()">
          Save changes
        </common-button>
      </template>

      <common-loader
        v-else
        flex
        relative
        minHeightAuto
        style="padding: 100px 0"
      />
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import { uniqWith, isEqual } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

import SelectMultipleEntities from '@/components/mySubAccounts/SelectMultipleEntities.vue'
import MyUsersSetSpendLimit from '@/components/mySubAccounts/MyUsersSetSpendLimit.vue'

const { mapActions } = createNamespacedHelpers('users')

export default {
  name: 'MyUsersEditMultiplePanel',
  components: {
    MyUsersSetSpendLimit,
    SelectMultipleEntities,
  },
  mixins: [
    panelVModel,
    getTooltipTextBySlug,
  ],
  props: {
    selectedUsersIds: {
      type: Array,
      required: false,
      default: () => ([])
    },
  },
  data: () => ({
    loading: false,
    options: {
      budgetEntitiesList: [],
      categoriesList: [],
      approversList: [],
      inventoryItemsList: [],
      campaignsList: [],
      spendLimitTimeFrames: [],
    },
    form: {
      userOrdersRequireApproval: false,
      setSpendLimit: false,
      spendLimit: {
        limit: null,
        time: null,
      },
      orderApprover: null,
      selectedBudgetEntities: [],
      selectedCategories: [],
      selectedInventoryItems: [],
      selectedCampaigns: [],
    },
    validation: { approverRequired: v => v !== null || '' },
  }),
  computed: {
    numberOfSelectedUsers () {
      return this.selectedUsersIds?.length ?? 0
    },
    availableApprovers () {
      const { approversList } = this.options

      const result = [
        { approver_id: 0, name: 'All admins', },
      ]
      result.push(...approversList)

      return result ?? []
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        Object.assign(this.$data, this.$options.data())
        this.getSelectOptions()
      }
    }
  },
  methods: {
    ...mapActions(['getUsers']),
    getSelectOptions () {
      this.loading = true
      const promise1 = Api.get('/customer/subaccounts/additional-data')
        .then(({
          data: {
            campaigns,
            access_to_categories,
            order_approvers,
            budget_entities,
            inventory_products,
            spend_limit_time_frames,
          },
        }) => {
          this.options.categoriesList = access_to_categories ?? []
          this.options.approversList = order_approvers ?? []
          this.options.budgetEntitiesList = budget_entities ?? []
          this.options.inventoryItemsList = inventory_products ?? []
          this.options.spendLimitTimeFrames = spend_limit_time_frames ?? []
          this.options.campaignsList = campaigns ?? []
        })
        .catch((e) => (console.error(e)))

      const promise2 = Api.get('/customer/subaccounts/multiple', {
        params: { ids: this.selectedUsersIds?.join(',') }
      })
        .then(({ data }) => (this.checkIfAnyDataIsSame(data)))

      Promise.all([promise1, promise2])
        .finally(() => (this.loading = false))
    },
    checkIfAnyDataIsSame (usersData = []) {
      const spendLimitEnabled = [...new Set(usersData?.map((user) => user.spend_limit_enabled))]
      const spendLimit = uniqWith(usersData?.map((user) => user.spend_limit), isEqual)
      const orderApprover = [...new Set(usersData?.map((user) => user.order_approver))]
      const budgetEntities = uniqWith(
        usersData?.map((user) => user.budget_entities?.map((budget) => budget.id)),
        isEqual,
      )
      const categories = uniqWith(
        usersData?.map((user) => user.access_to),
        isEqual,
      )
      const inventoryProducts = uniqWith(
        usersData?.map((user) => user.inventory_products?.map((inventoryProduct) => inventoryProduct.id)),
        isEqual,
      )
      const campaigns = uniqWith(
        usersData?.map((user) => user.campaigns?.map((campaign) => campaign.id)),
        isEqual,
      )

      if (spendLimitEnabled.length === 1) { this.form.setSpendLimit = spendLimitEnabled?.at(0) ?? false }
      if (spendLimit.length === 1) {
        const spData = spendLimit.at(0)

        this.form.spendLimit = {
          limit: spData?.amount ?? null,
          time: spData?.time_frame ?? null,
        }
      }
      if (orderApprover.length === 1) { this.form.orderApprover = orderApprover?.at(0) ?? null }
      if (budgetEntities.length === 1) { this.form.selectedBudgetEntities = budgetEntities?.at(0) ?? [] }
      if (categories.length === 1) { this.form.selectedCategories = categories?.at(0) ?? null }
      if (inventoryProducts.length === 1) { this.form.selectedInventoryItems = inventoryProducts?.at(0) ?? [] }
      if (campaigns.length === 1) { this.form.selectedCampaigns = campaigns?.at(0) ?? [] }
      if (this.form.orderApprover !== null) { this.form.userOrdersRequireApproval = true }
    },
    updateMultipleUsers () {
      if (!this.$refs.form.validate()) { return }

      const { form, selectedUsersIds } = this
      const { setSpendLimit, spendLimit, selectedInventoryItems } = form

      const spendLimitData = (spendLimit && form.spendLimit.limit && form.spendLimit.time)
        ? ({ amount: form.spendLimit.limit, time_frame: form.spendLimit.time, })
        : null
      
      const budgetEntities = form.selectedBudgetEntities.map((budgetId) => ({
        id: budgetId,
        limit: null,
        time: null,
      }))

      this.loading = true
      Api.put(`/customer/subaccounts/multiple`,
        {
          access_to: form.selectedCategories,
          order_approver: form.userOrdersRequireApproval ? form.orderApprover : null,
          spend_limit_enabled: setSpendLimit,
          spend_limit: spendLimitData,
          inventory_products: selectedInventoryItems.map((id) => ({ id })) ?? [],
          campaigns: form.selectedCampaigns?.map((val) => ({ id: val })) ?? [],
          budget_entities: budgetEntities,
        },
        { params: { ids: selectedUsersIds?.join(',') } }
      )
        .then(() => {
          this.show = false
          this.$cgToast.successBold('Changes saved successfully')
          this.getUsers()
        })
        .catch(() => {
          this.$cgToast.error('An error occurred, please contact our support')
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.my-users-edit-multiple-panel {
  &::v-deep > .v-navigation-drawer__content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 70px;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;

    & > div {
      color: #000;
      font-family: 'Lato-Light', sans-serif;
      font-size: 40px;
      line-height: 44px;

      & > b {
        font-family: 'Lato-Bold', sans-serif;
      }
    }

    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > label {
      font-family: 'Lato-Bold', sans-serif;
      font-size: 20px;
      line-height: 24px;
      color: #222325;
    }

    &::v-deep .common-checkbox__content--bold {
      font-size: 20px;
      line-height: 24px;
      gap: 8px;
      align-items: center;
      color: #222325 !important;
    }
  }
}
</style>
