<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" class="page-title text-capitalize">
        Account settings
      </v-col>

      <v-col cols="12" v-if="loading">
        <common-loader style="padding: 200px 0" />
      </v-col>

      <v-col cols="12" v-else>
        <v-row no-gutters class="flex-column">
          <v-col cols="5" xl="3">
            <account-settings-form
              ref="account-settings-form"
              :firstName.sync="form.firstname"
              :lastName.sync="form.lastname"
              :displayName.sync="form.display_name"
              :email.sync="form.email"
              :phoneNumber.sync="form.phone"
            />
          </v-col>

          <template v-if="masterAccount || superAdmin">
            <v-col cols="8" class="page-title text-capitalize pt-6 pb-3">
              Default billing address
            </v-col>
            <v-col cols="5" xl="3">
              <default-billing-address-form
                ref="billing-address-form"
                :apt.sync="billingAddressForm.apt"
                :city.sync="billingAddressForm.city"
                :country.sync="billingAddressForm.country"
                :firstName.sync="billingAddressForm.firstname"
                :lastName.sync="billingAddressForm.lastname"
                :postcode.sync="billingAddressForm.postcode"
                :region.sync="billingAddressForm.region"
                :street.sync="billingAddressForm.street"
                :telephone.sync="billingAddressForm.telephone"
              />
            </v-col>
          </template>

          <v-col cols="5" xl="3" class="py-10">
            <span
              class="green1 f15 pointer"
              @click="showPasswordForm = true"
              v-if="!showPasswordForm"
            >
              Change password
            </span>

            <div class="page-title text-capitalize pb-3" v-if="showPasswordForm">
              Change password
            </div>

            <account-settings-password-form
              :oldPassword.sync="passwordForm.oldPassword"
              :newPassword.sync="passwordForm.newPassword"
              v-if="showPasswordForm"
            />
          </v-col>
          <v-col cols="10" class="pb-8 d-flex">
            <common-button
              height="36"
              class="px-10"
              @click="saveChanges()"
              :disabled="disableCTAButton"
            >
              Save
            </common-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '../axios/api'
import { isEqual } from 'lodash'
import superAdmin from '@/mixins/superAdmin'
import masterAccount from '@/mixins/masterAccount'

import AccountSettingsForm from '@/components/accountSettings/AccountSettingsForm.vue'
import AccountSettingsPasswordForm from '@/components/accountSettings/AccountSettingsPasswordForm.vue'
import DefaultBillingAddressForm from '@/components/accountSettings/DefaultBillingAddressForm.vue'

export default {
  name: 'AccountSettings',
  components: {
    AccountSettingsForm,
    DefaultBillingAddressForm,
    AccountSettingsPasswordForm,
  },
  mixins: [
    superAdmin,
    masterAccount,
  ],
  data: () => ({
    loading: false,
    userData: {
      display_name: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null
    },
    form: {
      display_name: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null
    },
    billingAddressData: {
      firstname: null,
      lastname: null,
      street: null,
      apt: null,
      city: null,
      postcode: null,
      country: null,
      region: null,
      telephone: null,
    },
    billingAddressForm: {
      firstname: null,
      lastname: null,
      street: null,
      apt: null,
      city: null,
      postcode: null,
      country: null,
      region: null,
      telephone: null,
    },
    showPasswordForm: false,
    passwordForm: {
      oldPassword: null,
      newPassword: null
    }
  }),
  computed: {
    disableCTAButton () {
      const { userData, form, billingAddressData, billingAddressForm, passwordForm } = this
      if (passwordForm.oldPassword && passwordForm.newPassword) return false
      if (!this.compareObjects(userData, form)) return false
      if (!this.compareObjects(billingAddressData, billingAddressForm)) return false

      return true
    }
  },
  created () {
    this.loading = true
    Api.get('/customer/my-account').then(({ data }) => {
      this.setUserData(data)
      this.setBillingAddressData(data)
      this.loading = false
    })
  },
  methods: {
    setUserData ({ display_name, email, firstname, lastname, phone }) {
      this.form = {
        firstname: firstname,
        lastname: lastname,
        display_name: display_name,
        email: email,
        phone: phone,
      }
      this.userData = {
        display_name: display_name,
        email: email,
        firstname: firstname,
        lastname: lastname,
        phone: phone,
      }
    },
    setBillingAddressData ({ billing_address: billingAddress }) {
      if (billingAddress) {
        this.billingAddressData = {
          firstname: billingAddress.firstname,
          lastname: billingAddress.lastname,
          street: billingAddress.street,
          apt: billingAddress.apt,
          city: billingAddress.city,
          postcode: billingAddress.postcode,
          country: billingAddress.country,
          region: billingAddress.region,
          telephone: billingAddress.telephone,
        }
        this.billingAddressForm = {
          firstname: billingAddress.firstname,
          lastname: billingAddress.lastname,
          street: billingAddress.street,
          apt: billingAddress.apt,
          city: billingAddress.city,
          postcode: billingAddress.postcode,
          country: billingAddress.country,
          region: billingAddress.region,
          telephone: billingAddress.telephone,
        }
      }
    },
    compareObjects (obj1, obj2) {
      return isEqual(obj1, obj2)
    },
    saveChanges () {
      const { masterAccount, superAdmin } = this
      const isSettingsFormValid = this.$refs['account-settings-form']?.validate() ?? false
      const isBillingFormValid = this.$refs['billing-address-form']?.validate() ?? false

      if (!isSettingsFormValid) return
      if ((masterAccount || superAdmin) && !isBillingFormValid) return

      this.loading = true
      const { form, showPasswordForm, passwordForm, billingAddressForm } = this
      const data = {
        ...form,
        billing_address: {
          ...billingAddressForm
        },
      }

      if (showPasswordForm && passwordForm.oldPassword && passwordForm.newPassword) {
        data.old_password = passwordForm.oldPassword
        data.new_password = passwordForm.newPassword
      }

      Api.put('/customer/my-account', data).then(({ data: responseData, success }) => {
        if (success) {
          Object.assign(this.$data.passwordForm, this.$options.data().passwordForm)
          this.showPasswordForm = false
          this.$cgToast.successBold('Details saved successfully!')

          this.setUserData(responseData)
          this.setBillingAddressData(responseData)
        }
      }).catch(({ response: { data: { errors, message } } }) => {
        if (message) {
          this.$cgToast.error(message)
        } else {
          const errorsArray = Object.values(errors)?.reduce((acc, error) => {
            if (Array.isArray(error)) {
              acc.push(...error)
            } else {
              acc.push(error)
            }
            return acc
          }, [])

          if (errorsArray.length) {
            this.$cgToast.error(errorsArray.join('<br/>'))
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
