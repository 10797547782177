import getFormDefaultState from './formDefaultState'

export default () => ({
  source: {
    loading: false,
    collections: [],
    favorites: [],
    inventory: [],
  },
  alreadyUsedNames: [],
  userLogos: [],
  breadcrumbs: [
    {
      routeName: 'CreateCampaignSource',
      title: 'Select gifts',
    },
  ],
  form: {
    ...getFormDefaultState()
  },
})
