<template>
  <div class="review-save-cancel-area">
    <div class="review-save-cancel-area__icon-wrapper" @click.stop="$emit('onCancel')">
      <icons-close-circle
        :height="18"
        :width="18"
        color="currentColor"
      />
    </div>

    <div class="review-save-cancel-area__icon-wrapper" @click.stop="$emit('onSave')">
      <icons-check-circle
        :height="18"
        :width="18"
        :check-stroke-width="1.4"
        outlineCheckColor="currentColor"
        outlineColor="#42B77A"
        fillColor="currentColor"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ReviewSaveCancelIcons extends Vue {}
</script>

<style lang="scss" scoped>
.review-save-cancel-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
  margin-top: 2px;

  &__icon-wrapper {
    cursor: pointer;
    color: #95979D;

    & > svg {
      fill: #fff;
      color: inherit;
    }

    &:nth-child(1):hover > svg {
      fill: #FF5A60;
      color: #fff;
    }

    &:nth-child(2) {
      color: #42B77A;

      &::v-deep circle {
        color: #fff;
      }

      &:hover {
        color: #fff;

        &::v-deep circle {
          color: #42B77A;
        }
      }
    }
  }
}
</style>
