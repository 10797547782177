<template>
  <div>
    <green-text-with-plus
      class="pb-6"
      :isOpen="showAdvanced"
      @onClick="showAdvanced = !showAdvanced"
    >
      {{ showAdvanced ? 'Hide advanced options' : 'Advanced options' }}

      <popper
        :options="{
          placement: 'top',
          modifiers: { offset: { offset: '0, 5px' } },
        }"
      >
        <div class="popper pa-4 text-left" style="min-width: 200px; max-width: 500px;">
          <span
            v-html="getTooltipTextBySlug(!isPageInIframe ? 'egift_advanced_options' : 'egift_advanced_options_iframe')"
          />
        </div>
        <div slot="reference" class="d-flex ml-2">
          <icons-question width="24" height="24" class="pointer" />
        </div>
      </popper>
    </green-text-with-plus>

    <v-expand-transition>
      <v-row v-show="showAdvanced">
        <v-col cols="12" v-if="!isPageInIframe">
          <common-checkbox v-model="theAllowToExchange" :disabled="disableUpgradeCheckbox">
            <span>
              Allow recipient to exchange
            </span>

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-3 text-left" style="min-width: 300px; max-width: 480px;">
                {{ getTooltipTextBySlug('campaigns_allow_to_exchange') }}
              </div>
              <div slot="reference" class="d-flex ml-2">
                <icons-question width="24" height="24" class="pointer" />
              </div>
            </popper>
          </common-checkbox>
        </v-col>

        <v-col cols="12" v-if="!isPageInIframe">
          <common-checkbox v-model="theAllowToReEgift" :disabled="disableReGiftCheckbox">
            <span>
              Allow recipient to Re-gift
            </span>

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-3 text-left" style="min-width: 300px; max-width: 450px;">
                {{ getTooltipTextBySlug('campaigns_allow_to_re-egift') }}
              </div>
              <div slot="reference" class="d-flex ml-2">
                <icons-question width="24" height="24" class="pointer" />
              </div>
            </popper>
          </common-checkbox>
        </v-col>

        <v-col
          cols="12"
          v-if="!isPageInIframe &&
            panelType === 'link' &&
            theLinkType !== 'single_link_unrestricted'"
        >
          <label class="pb-4">Select type of gift link:</label>

          <common-radio v-model="theLinkType" inputValue="standard_link" class="mb-6">
            <div class="send-egift-panel-advanced-options__link-type">
              <div>
                Standard
              </div>
              <div>
                Unique link for each recipient
              </div>
            </div>
          </common-radio>

          <verified-link-type-tooltip :disabled="!disableRestrictedGiftLinkType">
            <common-radio
              v-model="theLinkType"
              inputValue="single_link_restricted"
              :disabled="disableRestrictedGiftLinkType"
            >
              <div class="send-egift-panel-advanced-options__link-type">
                <div>
                  Single link - Verified
                </div>
                <div>
                  Same link is sent to all recipients
                </div>
              </div>
            </common-radio>
          </verified-link-type-tooltip>
        </v-col>

        <v-col cols="12" v-if="theLinkType === 'single_link_restricted'">
          <label for="welcome-message">Redeem page welcome message</label>

          <common-input
            v-model="theWelcomeMessage"
            :rules="[(v) => !!v || '']"
            style="max-width: 520px"
            maxLength="450"
            placeholder="Enter your details to claim your gift"
          >
            <template #append-outer>
              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
                  {{ getTooltipTextBySlug('campaigns_welcome_message') }}
                </div>
                <div slot="reference" class="d-flex ml-5">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </template>
          </common-input>

          <div class="send-egift-panel-advanced-options__input-counter">
            Characters left: {{ 450 - theWelcomeMessage.length }}
          </div>
        </v-col>

        <v-col cols="12" v-if="!isPageInIframe && !isPreProduction && false">
          <label for="shipping-date">
            Shipping arrival date
          </label>
          <div class="d-flex align-center" style="margin-left: 4px">
            <single-date-range-picker
              v-model="theShippingDate"
              id="shipping-date"
              placeholder="Shipping arrival date"
              style="position: relative;"
              set-min-date-week-later
              picker-style="--min-width: 220px;--picker-margin-top: -1px"
            />

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
                {{ getTooltipTextBySlug('campaigns_shipping_dispatch_date') }}
              </div>
              <div slot="reference" class="d-flex ml-2">
                <icons-question width="24" height="24" class="pointer" />
              </div>
            </popper>
          </div>
        </v-col>

        <v-col cols="12" v-if="panelType === 'egift'">
          <label for="email-subject-line">
            Edit email subject line
          </label>
          <common-input
            v-model="theSubjectLine"
            id="email-subject-line"
            placeholder="Enter your subject line"
            height="36"
          >
            <template #append-outer>
              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="max-width: unset">
                  <img
                    alt=""
                    width="590"
                    height="150"
                    :src="getProductImageBySlug('campaigns_edit_email_subject')"
                  />
                </div>
                <div slot="reference" class="d-flex ml-5">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </template>
          </common-input>
        </v-col>

        <v-col v-if="userIsCgElite" cols="12">
          <common-radio v-model="userLink" inputValue="calendly" class="mb-6">
            Add a Calendar meeting link:

            <template #non-clickable>
              <div class="send-egift-panel-advanced-options__user-link-wrapper">
                <common-input
                  v-model="theCalendlyUrl"
                  :disabled="userLink !== 'calendly'"
                  placeholder="Enter your Calendar URL"
                  height="36"
                >
                  <template #append-outer>
                    <popper
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0, 5px' } },
                      }"
                    >
                      <div class="popper pa-4 text-left" style="max-width: unset">
                        <img
                          alt=""
                          width="470"
                          height="280"
                          :src="getProductImageBySlug('campaigns_calendly_meeting_link')"
                        />
                      </div>
                      <div slot="reference" class="d-flex ml-5">
                        <icons-question width="24" height="24" class="pointer" />
                      </div>
                    </popper>
                  </template>
                </common-input>
              </div>
            </template>
          </common-radio>
          <common-radio v-model="userLink" inputValue="free-form">
            Add a free form link:

            <template #non-clickable>
              <div class="send-egift-panel-advanced-options__user-link-wrapper">
                <common-input
                  v-model="freeFormLinkText"
                  :disabled="userLink !== 'free-form'"
                  placeholder="https://"
                  height="36"
                >
                  <template #append-outer>
                    <popper
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '0, 5px' } },
                      }"
                    >
                      <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
                        You can add any URL to redirect your recipients
                      </div>
                      <div slot="reference" class="d-flex ml-5">
                        <icons-question width="24" height="24" class="pointer" />
                      </div>
                    </popper>
                  </template>
                </common-input>

                <div class="d-flex flex-column">
                  <label for="button-label" class="pb-2 lato-regular">
                    Set button label (max 20 characters):
                  </label>
                  <common-input
                    v-model="freeFormLinkButton"
                    :disabled="userLink !== 'free-form'"
                    id="button-label"
                    placeholder="Go!"
                    height="36"
                    maxLength="20"
                  />
                </div>
              </div>
            </template>
          </common-radio>
        </v-col>

        <v-col v-else cols="12">
          <label for="calendly-url">
            Add a Calendar meeting link
          </label>
          <common-input
            v-model="theCalendlyUrl"
            id="calendly-url"
            placeholder="Enter your Calendar URL"
            height="36"
          >
            <template #append-outer>
              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="max-width: unset">
                  <img
                    alt=""
                    width="470"
                    height="280"
                    :src="getProductImageBySlug('campaigns_calendly_meeting_link')"
                  />
                </div>
                <div slot="reference" class="d-flex ml-5">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </template>
          </common-input>
        </v-col>

        <v-col cols="12">
          <label for="video-url">
            Add a video greeting
          </label>
          <common-input
            v-model="theVideoUrl"
            id="video-url"
            placeholder="Enter your greeting video URL"
            @click:clear="hideGreetingVideo"
            clearable
            @blur="showGreetingVideo"
            height="36"
          >
            <template #append-outer>
              <popper
                :options="{
                  placement: 'top',
                  modifiers: { offset: { offset: '0, 5px' } },
                }"
              >
                <div class="popper pa-4 text-left" style="max-width: unset">
                  <img
                    alt=""
                    width="534"
                    height="360"
                    :src="getProductImageBySlug('campaigns_video_greeting_link')"
                  />
                </div>
                <div slot="reference" class="d-flex ml-5">
                  <icons-question width="24" height="24" class="pointer" />
                </div>
              </popper>
            </template>
          </common-input>
        </v-col>

        <v-col cols="12" v-if="greetingVideo">
          <video
            :src="videoData.url"
            v-if="videoData.showVideo"
            frameborder="0"
            title="Greeting video"
            allowfullscreen="false"
            webkitallowfullscreen="false"
            mozallowfullscreen="false"
            controls
            width="730"
            height="460"
          />

          <iframe
            :src="videoData.url"
            v-if="videoData.showIframe"
            frameborder="0"
            title="Greeting video"
            controls
            width="730"
            height="460"
          />
        </v-col>

        <v-col cols="12">
          <div class="send-egift-panel-advanced-options__expiration-input">
            <label for="egift-expiration-time">eGift will expire after</label>
            <div>
              <cg-input
                v-model.number="campaignExpirationDays"
                type="number"
                :width="80"
                :validation="[required(), campaignExpirationDaysValidation]"
                id="egift-expiration-time"
              />

              days
            </div>
          </div>
        </v-col>

        <v-col cols="12" v-if="panelType === 'egift'">
          <common-checkbox
            v-model="theDisableReminderEmails"
            :disabled="disableEmailRemindersCheckbox"
          >
            <span>
              Disable reminder emails to recipients
            </span>

            <popper
              :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }"
            >
              <div class="popper pa-4 text-left" style="min-width: 300px; max-width: 500px;">
                {{ getTooltipTextBySlug('campaigns_disable_reminder_emails') }}
              </div>
              <div slot="reference" class="d-flex ml-2">
                <icons-question width="24" height="24" class="pointer" />
              </div>
            </popper>
          </common-checkbox>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import GreenTextWithPlus from '@/components/myCampaign/common/GreenTextWithPlus.vue'
import SingleDateRangePicker from '@/components/myCampaign/common/SingleDateRangePicker.vue'
import VerifiedLinkTypeTooltip from '../TheVerifiedLinkTypeTooltip.vue'
import { CgInput } from '@corporategift/design-system'

import { required } from '@corporategift/design-system/validations'
import getProductImageBySlug from '@/mixins/getProductImageBySlug'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'
import isPageInIframe from '../mixins/isPageInIframe'
import userIsCgElite from '@/mixins/userIsCgElite'
import panelType from '../mixins/panelType'

import campaignExpirationDaysValidation from '@/utils/campaignExpirationDaysValidation'

export default {
  name: 'AdvancedOptions',
  components: {
    Popper,
    CgInput,
    GreenTextWithPlus,
    SingleDateRangePicker,
    VerifiedLinkTypeTooltip,
  },
  mixins: [
    panelType,
    isPageInIframe,
    userIsCgElite,
    getTooltipTextBySlug,
    getProductImageBySlug,
  ],
  props: {
    recipients: {
      type: Array,
      required: false,
      default: () => ([])
    },
    // checkboxes
    allowToExchange: {
      type: Boolean,
      required: true
    },
    allowToReEgift: {
      type: Boolean,
      required: true
    },
    disableReminderEmails: {
      type: Boolean,
      required: true
    },
    // advanced form
    shippingDate: {
      type: String,
      required: false,
      default: null
    },
    expirationDays: {
      type: [Number, String],
      required: false,
      default: null,
    },
    linkType: {
      type: String,
      required: false,
      default: null
    },
    subjectLine: {
      type: String,
      required: false,
      default: null
    },
    userLinkType: {
      type: String,
      required: false,
      default: null,
    },
    freeFormLink: {
      type: String,
      required: false,
      default: null,
    },
    freeFormButton: {
      type: String,
      required: false,
      default: 'Go!',
    },
    calendlyUrl: {
      type: String,
      required: false,
      default: null
    },
    videoUrl: {
      type: String,
      required: false,
      default: null
    },
    welcomeMessage: {
      type: String,
      required: false,
      default: null
    },
    egiftSettings: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isPreProduction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    showAdvanced: false,
    greetingVideo: false,
    videoData: {
      url: null,
      showIframe: false,
      showVideo: false
    }
  }),
  computed: {
    disableRestrictedGiftLinkType () {
      return !this.recipients.every((item) => item.first_name && item.email) ?? true
    },
    theAllowToExchange: {
      get () {
        return this.allowToExchange
      },
      set (val) {
        this.$emit('update:allowToExchange', val)
      }
    },
    theAllowToReEgift: {
      get () {
        return this.allowToReEgift
      },
      set (val) {
        this.$emit('update:allowToReEgift', val)
      }
    },
    theDisableReminderEmails: {
      get () {
        return this.disableReminderEmails
      },
      set (val) {
        this.$emit('update:disableReminderEmails', val)
      }
    },
    userLink: {
      get () { return this.userLinkType },
      set (val) { this.$emit('update:userLinkType', val) }
    },
    freeFormLinkText: {
      get () { return this.freeFormLink },
      set (val) { this.$emit('update:freeFormLink', val) }
    },
    freeFormLinkButton: {
      get () { return this.freeFormButton },
      set (val) { this.$emit('update:freeFormButton', val) }
    },
    theShippingDate: {
      get () {
        return this.shippingDate
      },
      set (val) {
        this.$emit('update:shippingDate', val)
      }
    },
    theLinkType: {
      get () {
        return this.linkType
      },
      set (val) {
        this.$emit('update:linkType', val)
      }
    },
    theWelcomeMessage: {
      get () {
        return this.welcomeMessage
      },
      set (val) {
        this.$emit('update:welcomeMessage', val)
      }
    },
    theSubjectLine: {
      get () {
        return this.subjectLine
      },
      set (val) {
        this.$emit('update:subjectLine', val)
      }
    },
    theCalendlyUrl: {
      get () {
        return this.calendlyUrl
      },
      set (val) {
        this.$emit('update:calendlyUrl', val)
      }
    },
    theVideoUrl: {
      get () {
        return this.videoUrl
      },
      set (val) {
        this.$emit('update:videoUrl', val)
      }
    },
    disableEmailRemindersCheckbox () {
      return !this.egiftSettings?.can_override_disable_recipient_reminders ?? false
    },
    disableReGiftCheckbox () {
      return !this.egiftSettings?.can_override_is_regift_enabled ?? false
    },
    disableUpgradeCheckbox () {
      return !this.egiftSettings?.can_override_is_upgrade_enabled ?? false
    },
    campaignExpirationDays: {
      get () { return this.expirationDays },
      set (val) { this.$emit('update:expirationDays', val) }
    },
  },
  watch: {
    showAdvanced: function (val) {
      if (val && this.videoUrl) {
        this.showGreetingVideo()
      }
    },
    theVideoUrl: function (val) {
      if (val) {
        this.showGreetingVideo()
      }
    }
  },
  methods: {
    required,
    campaignExpirationDaysValidation,
    clearData () {
      Object.assign(this.$data, this.$options.data())
    },
    showGreetingVideo () {
      try {
        const url = new URL(this.videoUrl)
        const takeArgumentFromPathnameById = (pathname, index, splitBy = '/') => pathname.split(splitBy)[index]
        // for youtu.be and youtube links
        if (url.hostname.includes('youtu')) {
          const takeYoutubeId = (url) => url.match(/(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/)[2]
          this.videoData.showIframe = true
          this.videoData.showVideo = false
          // take video id and create youtube embed link
          this.videoData.url = `https://youtube.com/embed/${takeYoutubeId(this.videoUrl)}`
        } else if (url.hostname.includes('loom')) {
          this.videoData.showIframe = true
          this.videoData.showVideo = false
          // take video id and create loom embed link
          this.videoData.url = `https://www.loom.com/embed/${takeArgumentFromPathnameById(url.pathname, [2])}`
        } else if (url.hostname.includes('kommute')) {
          this.videoData.showIframe = true
          this.videoData.showVideo = false
          // take video id and create vimail embed link
          this.videoData.url = `https://www.kommute.com/to/mymail/${takeArgumentFromPathnameById(url.pathname, [3])}/embed?width=730&height=460`
        } else if (url.hostname.includes('vidyard')) {
          this.videoData.showIframe = true
          this.videoData.showVideo = false
          // take video id and create vidyard embed link
          this.videoData.url = `https://play.vidyard.com/${takeArgumentFromPathnameById(url.pathname, [2])}?disable_popouts=1&v=4.3.10&type=inline`
        } else {
          this.videoData.showIframe = false
          this.videoData.showVideo = true
          this.videoData.url = this.videoUrl
        }
        this.greetingVideo = true
      } catch (e) {
        console.error('Invalid Url')
      }
    },
    hideGreetingVideo () {
      this.theVideoUrl = null
      this.greetingVideo = false
      this.videoData = {
        url: null,
        showIframe: false,
        showVideo: false
      }
    }
  }
}
</script>

<style lang="scss">
// for v-html
.popper > span > ul > li {
  list-style: unset !important;
}
</style>

<style lang="scss" scoped>
label {
  font-family: 'Lato-Bold', sans-serif !important;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  padding-bottom: 8px;
  display: block;

  &.lato-regular {
    font-family: 'Lato-Regular', sans-serif !important;
  }
}
</style>

<style lang="scss" scoped>
.send-egift-panel-advanced-options {
  &__link-type {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > div {
      &:first-child {
        font-family: 'Lato-Regular', sans-serif;
        color: #000;
        font-size: 15px;
      }

      &:last-child {
        font-family: 'Lato-Italic', sans-serif;
        color: #95979D;
        font-size: 15px;
      }
    }
  }

  &__user-link-wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 8px 0 0 30px;
  }

  &__input-counter {
    max-width: 467px;
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    color: #95979D;
    font-size: 15px;
    font-family: 'Lato-Italic', 'Lato Italic', sans-serif;
  }

  &__expiration-input {
    display: flex;
    flex-direction: column;

    & > label {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #222325;
      text-align: left;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
