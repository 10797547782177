var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('date-range-picker',{ref:"picker",staticClass:"cg-single-date-range-picker cg-date-range-picker",class:{
      'cg-single-date-range-picker--disabled': _vm.disabled,
      'cg-single-date-range-picker--no-ranges': _vm.hideRanges
    },style:(_vm.pickerStyle),attrs:{"opens":_vm.opens,"minDate":_vm.allowPastDates ? null : _vm.minDate,"maxDate":_vm.maxDate,"singleDatePicker":_vm.singleDatePicker,"timePicker":_vm.timePicker,"timePicker24Hour":_vm.timePicker24Hour,"showWeekNumbers":_vm.showWeekNumbers,"showDropdowns":_vm.showDropdowns,"autoApply":_vm.autoApply,"linkedCalendars":_vm.linkedCalendars,"ranges":_vm.hideRanges ? null : _vm.dateRanges,"locale-data":_vm.customOptions,"disabled":_vm.disabled},on:{"update":_vm.updateValues},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('popper',{attrs:{"disabled":!_vm.disabled || _vm.hideTooltip,"options":{
          placement: 'top',
          modifiers: { offset: { offset: '0, 4px' } }
        }}},[_c('div',{staticClass:"popper px-4 py-2 text-center",staticStyle:{"max-width":"230px","width":"230px"}},[_vm._v(" To choose a future send date you must be a CG Elite user. Contact sales for more information. ")]),_c('v-text-field',{staticClass:"rounded-0 date-range-form thin-border",staticStyle:{"max-width":"130px","min-height":"36px","pointer-events":"auto"},attrs:{"slot":"reference","placeholder":_vm.placeholder,"color":"black","outlined":"","readonly":"","disabled":_vm.disabled,"solo":"","flat":"","dense":"","hide-details":"","tile":"","height":_vm.height},slot:"reference",scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('icons-calendar')]},proxy:true}]),model:{value:(_vm.rangeValue),callback:function ($$v) {_vm.rangeValue=$$v},expression:"rangeValue"}})],1)]},proxy:true},{key:"ranges",fn:function(ranges){return [_c('div',{staticClass:"ranges"},[_c('ul',_vm._l((ranges.ranges),function(range,name){return _c('li',{key:name,class:name === _vm.selectedRange ? 'active' : '',on:{"click":[function($event){return ranges.clickRange(range)},function($event){return _vm.setRange(name)}]}},[_c('span',{staticClass:"f14"},[_vm._v(_vm._s(name))]),(name === 'This month' || name === 'Last month')?_c('span',{staticClass:"f14 ml-1"},[_vm._v(" ("+_vm._s(_vm.getMonth(range[0].toDateString()))+") ")]):_vm._e()])}),0)])]}},(_vm.showCheckbox)?{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[_c('common-checkbox',{model:{value:(_vm.checkboxModel),callback:function ($$v) {_vm.checkboxModel=$$v},expression:"checkboxModel"}},[_vm._v(" Apply for all recipients ")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }