<template>
<div>
  <p class="f14 lato-bold mb-0" v-if="isDisplayName">{{ `${address.first_name} ${address.last_name}` }}</p>
  <p class="f14 mb-0">{{ capitalizeFirstLetter(address.street) }}</p>
  <p class="f14 mb-0">
    {{ capitalizeFirstLetter(address.city) }},
    {{ address.region | convertRegionToAbbr }}
    {{ address.postcode }}
  </p>
  <p class="f14 mb-0">{{ addressCountry }}</p>
  <p class="f14 mb-0" v-if="!isCustomPhone">Phone: {{ address.telephone }}</p>
  <slot v-else></slot>
</div>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils'

export default {
  name: 'AddressInfo',
  props: {
    address: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCustomPhone: {
      type: Boolean,
      default: false
    },
    isDisplayName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    addressCountry () {
      return new Intl.DisplayNames(['en'], { type: 'region' }).of(this.address.country || 'US')
    }
  },
  methods: {
    capitalizeFirstLetter
  }
}
</script>

<style scoped>

</style>
