<template>
  <v-navigation-drawer
    v-model="show"
    v-lock-scroll="show"
    app
    temporary
    right
    width="850px"
    class="campaign-settings-panel"
  >
    <close-panel-button
      @close="() => show = false"
      text="Close"
    />

    <common-loader v-if="loading" style="max-height: 800px" />

    <v-container class="px-9 py-0" v-if="!loading && gift && show">
      <v-row class="flex-column">
        <v-col cols="12" class="campaign-settings-panel__title">
          Edit settings for
          <span class="campaign-settings-panel__title--bold">
            {{ gift.name }}
          </span>
        </v-col>

        <v-col cols="6" class="d-flex align-center g20">
          <label for="status-checkbox" class="gray4 f14 pointer">
            <strong class="f15 black1">Status:</strong>
            {{ isCampaignActive ? 'Active' : 'Disabled' }}
          </label>
          <common-switch v-model="isCampaignActive" id="status-checkbox" />
        </v-col>

        <v-col cols="7" class="pt-8 pb-14">
          <label for="tags">
            Tags associated with campaign/s:
          </label>

          <v-combobox
            v-model="selectedTags"
            multiple
            small-chips
            clearable
            :items="availableTags"
            append-icon="mdi-chevron-down"
            color="#62646A"
            ref="tags-select"
            outlined
            dense
            item-color="#62646A"
            item-value="id"
            item-text="name"
            hide-details
            class="campaigns-tags-combobox rounded-0 pt-1"
            @input="handleInputChange"
            :menu-props="{
              bottom: true,
              offsetY: true,
              left: true,
              tile: true,
              contentClass: 'campaigns-tags-combobox__select'
            }"
          >
            <template #selection="{ attrs, item, parent, selected }">
              <v-chip
                class="my-1"
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
                small
              >
                <span class="pr-2">
                  {{ item.name }}
                </span>

                <div class="d-flex align-center pointer" @click="parent.selectItem(item)">
                  <icons-close :width="10" :height="11" />
                </div>
              </v-chip>
            </template>

            <template #prepend-item v-if="userIsAnAdmin">
              <div
                class="px-4 pt-4 pb-2 campaigns-tags-combobox__new-tag"
                :class="{ 'campaigns-tags-combobox__new-tag--active': addNewTag }"
              >
                <div class="d-flex align-center" @click="addNewTag = !addNewTag">
                  <icons-plus-circle :width="28" :height="28" />

                  <template v-if="!addNewTag">
                    Add new tag
                  </template>
                </div>

                <div class="d-flex flex-grow-1 align-center" v-if="addNewTag">
                  <common-input v-model="newTagName" :height="30" :error="showNewTagError" />
                  <common-button :height="30" :loading="loadingNewTag" @click="sendNewTag()">
                    Add tag
                  </common-button>
                </div>
              </div>
            </template>

            <template #item="{ item, attrs }">
              <div class="campaigns-tags-combobox__select--item">
                <div
                  class="campaigns-tags-combobox__select--item--icon"
                  :class="{
                    'campaigns-tags-combobox__select--item--icon--selected': attrs['aria-selected'] === 'true'
                  }"
                />
                <div class="campaigns-tags-combobox__select--item--name">
                  {{ item.name }}
                </div>
              </div>
            </template>

            <template #no-data>
              <div class="gray3 f14 px-4 py-2">
                No data
              </div>
            </template>
          </v-combobox>
        </v-col>

        <v-col cols="7">
          <v-row dense>
            <v-col cols="12" class="d-flex justify-space-between">
              <label class="d-flex align-center g10">
                Users assigned to this campaign

                <common-badge>
                  {{ selectedUsers.length }}
                </common-badge>
              </label>

              <div class="d-flex">
                <add-users
                  v-model="selectedUsers"
                  :items="availableUsers"
                />
              </div>
            </v-col>

            <v-col cols="12" class="py-5">
              <common-input
                v-model="searchUser"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search user..."
                clearable
                height="30"
              />
            </v-col>

            <v-col cols="12" class="campaign-settings-panel__users">
              <template v-for="user in filteredSelectedUsers">
                <the-campaign-settings-panel-user
                  :key="user.id"
                  :user="user"
                  @remove="removeUser"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="7" class="pt-10 campaign-settings-panel__actions">
          <common-button
            :height="40"
            :disabled="disableCtaButton"
            style="max-width: 180px"
            @click="saveChanges()"
          >
            Save Changes
          </common-button>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/axios/api'

import TheCampaignSettingsPanelUser from '../TheCampaignSettingsPanelUser.vue'
import AddUsers from '../TheCampaignSettingsPanelAddUsers.vue'

import panelVModel from '@/mixins/panelVModel'
import gift from './mixins/gift'
import { isEqual } from 'lodash'

export default {
  name: 'TheCampaignSettingsPanel',
  components: {
    TheCampaignSettingsPanelUser,
    AddUsers
  },
  mixins: [
    panelVModel,
    gift
  ],
  data: () => ({
    loading: false,
    loadingNewTag: false,
    searchUser: null,
    addNewTag: false,
    newTagName: null,
    showNewTagError: false,
    isCampaignActive: true,
    selectedTags: [],
    availableTags: [],
    selectedUsers: [],
    availableUsers: [],
    initialData: {
      accounts: [],
      tags: []
    }
  }),
  computed: {
    filteredSelectedUsers() {
      const { selectedUsers, searchUser } = this
      if (!searchUser) return selectedUsers

      return selectedUsers.filter((user) => Object.values(user)?.join()?.toLowerCase()?.includes(searchUser?.toLowerCase()))
    },
    // TODO move to mixin
    userIsAnAdmin () {
      return this.$store.state.header?.headerData?.isAdmin || false
    },
    disableCtaButton () {
      const { selectedTags, selectedUsers, isCampaignActive, initialData } = this
      return isEqual(initialData.accounts, selectedUsers) &&
        isEqual(initialData.tags, selectedTags) &&
        isEqual(initialData.is_disabled, !isCampaignActive)
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loading = true
        const { entity_id: id } = this.gift
        const promises = []
        
        promises.push(Api.get(`/campaigns/${id}/settings`))
        promises.push(Api.get('/campaigns/tags'))
        promises.push(Api.get('/campaigns/accounts'))

        Promise.all(promises).then((response) => {
          this.selectedUsers = [...response[0].data?.accounts] ?? []
          this.selectedTags = [...response[0].data?.tags] ?? []
          this.isCampaignActive = !response[0].data?.is_disabled ?? true
          this.initialData = response[0].data
          this.availableTags = response[1].data ?? []
          this.availableUsers = response[2].data ?? []

          this.loading = false
        })
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    removeUser (user) {
      const { selectedUsers } = this
      const findIndex = selectedUsers?.findIndex((item) => item.id === user.id)

      const selectedItems = Object.assign([], selectedUsers)
      if (findIndex !== -1) selectedItems.splice(findIndex, 1)

      this.selectedUsers = selectedItems
    },
    // it checks if the user types a new value and removed everything that is not an object
    handleInputChange (newValue) {
      this.selectedTags = newValue.filter((item) => typeof item === 'object')
    },
    sendNewTag () {
      this.loadingNewTag = true
      this.showNewTagError = false

      Api.post('/campaigns/tags', {
        name: this.newTagName
      }).then(({ data, success }) => {
        if (success) {
          // check if tag from data object exist in tags
          if (!this.availableTags.some((item) => item.id === data.id)) this.availableTags.push(data)
          if (!this.selectedTags.some((item) => item.id === data.id)) this.selectedTags.push(data)

          this.addNewTag = false
          this.newTagName = null
          this.$refs['tags-select'].isMenuActive = false
        } else {
          this.showNewTagError = true
        }
      })
      .catch(() => (this.showNewTagError = true))
      .finally(() => (this.loadingNewTag = false))
    },
    saveChanges () {
      const { gift: { entity_id: id }, selectedTags, selectedUsers, isCampaignActive } = this
      this.loading = true

      Api.put(`/campaigns/${id}/settings`, {
        account_ids: selectedUsers.map((user) => user.id),
        tag_ids: selectedTags.map((tag) => tag.id),
        is_disabled: !isCampaignActive
      }).then(() => {
        this.$cgToast.successBold('Successfully updated campaign settings')
        this.$emit('fetchData')
        this.show = false
      }).catch(() => {
        this.$cgToast.error('An error occurred, please contact our support')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.campaign-settings-panel {
  &__title {
    font-family: 'Lato-Light', sans-serif;
    line-height: 44px;
    font-size: 40px;
    color: #000;

    span {
      font-family: inherit;
    }

    &--bold {
      font-family: 'Lato-Regular', sans-serif !important;
    }
  }

  label {
    font-family: 'Lato-Bold', sans-serif !important;
    line-height: 18px;
    font-size: 15px;
    color: #000;
  }

  &__users {
    & > div {
      border-bottom: 1px solid #D3D2D2;

      &:first-child {
        border-top: 1px solid #D3D2D2;
      }
    }
  }

  &__actions {
    bottom: 0;
    position: sticky;
    background: #fff;
  }
}
</style>

<style lang="scss">
.campaigns-tags-combobox {
  .v-input__slot {
    padding-left: 0 !important;
  }

  .v-select__selections {
    padding: 0 !important;

    & > .v-chip {
      font-family: 'Lato-Regular', sans-serif !important;
      background: #EDFCFB;
      line-height: 14px;
      font-size: 12px;
      color: #62646A;
    }
  }

  fieldset {
    border-width: 0 !important;
    padding-left: 0 !important;
  }

  &__new-tag {
    color: #42B77A;
    display: flex;
    height: 54px;
    align-items: center;
    cursor: pointer;

    & > div > svg {
      transition: all ease-in-out 0.3s;
      margin-right: 6px;
    }

    &--active {
      cursor: default;

      & > div > svg {
        cursor: pointer;
        transform: rotate(45deg);
      }
    }
  }

  &__select {
    .v-list {
      padding: 0 !important;
    }

    .v-list-item {
      padding: 0 !important;
    }

    &--item {
      cursor: pointer;
      display: flex;
      flex: 1 1 auto;
      padding: 0px 16px;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: #F5F5F5;
      }

      &--name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 155px;

        font-family: 'Lato-Regular', sans-serif;
        font-size: 14px;
        line-height: 37px;
        color: #000;
      }

      &--icon {
        display: flex;
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        border: 1px solid #DADADA;
        background: #fff;
        position: relative;

        &--selected {
          border: none;
          background-color: #42B77A;

          &::after {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%,-50%) rotate(45deg);
            content: ' ';
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
}
</style>
