<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    width="562"
  >
    <div class="create-campaign-exit-modal__wrapper">
      <div class="create-campaign-exit-modal__content">
        <div class="create-campaign-exit-modal__title">Are you sure?</div>

        <span class="create-campaign-exit-modal__text">
          You haven't saved changes to campaign. Are you sure you want to exit?
        </span>

        <div class="create-campaign-exit-modal__actions">
          <cg-button outlined @click="show = false">Continue edit campaign</cg-button>
          <cg-button :to="{ name: 'MyCampaignsPage' }">Exit</cg-button>
        </div>
      </div>

      <v-icon color="white" aria-label="Cancel" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import { CgButton } from '@corporategift/design-system'

export default {
  name: 'CreateCampaignCloseModal',
  components: { CgButton },
  mixins: [panelVModel],
}
</script>

<style lang="scss" scoped>
.create-campaign-exit-modal {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__content {
    gap: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  &__text {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;
  }

  &__actions {
    gap: 12px;
    display: flex;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }
  }
}
</style>
