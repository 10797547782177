<template>
  <ul>
    <li>
      <b>Type</b>
      {{ orderType }}
    </li>
    <li>
      <b>Status</b>

      <template v-if="orderStatus">
        {{ orderStatus | capitalizeFirstLetter }}

        <icons-question
          v-if="showTooltip"
          :width="18"
          :height="18"
          color="#62646A"
          v-tooltip="{
            content: tooltipText,
            classes: 'order-status-tooltip',
            container: false,
            offset: 4
          }"
        />
      </template>

      <template v-else-if="isOrderShipToAnArray">
        <v-popover
          placement="top"
          trigger="hover"
          :container="false"
        >
          <a role="button">
            {{ numberOfRecipients }} Recipients
          </a>

          <div slot="popover" class="order-item-details-recipients">
            <div v-for="(recipient, index) in availableRecipients" :key="index">
              {{ recipient.first_name }} {{ recipient.last_name }}

              <template v-if="recipient.status">
                ({{ recipient.status | capitalizeFirstLetter }})
              </template>
            </div>
          </div>
        </v-popover>
      </template>
    </li>

    <li v-if="orderHasApproval">
      <b>Approval</b>

      <v-popover
        placement="top"
        trigger="hover"
        :container="false"
      >
        <a role="button">{{ approvalStatus }}</a>

        <div slot="popover" class="order-item-details-recipients order-status-approval-tooltip">
          <div class="order-status-approval-tooltip__approver">{{ approverInfo }}</div>

          <template v-for="(approvalInfo, index) in approvalInfos">
            <div class="order-status-approval-tooltip__item" :key="approvalInfo.label + index">
              {{ approvalInfo.label }}
            </div>
            <div class="order-status-approval-tooltip__item" :key="approvalInfo.value + index">
              {{ approvalInfo.value }}
            </div>
          </template>
        </div>
      </v-popover>
    </li>
  </ul>
</template>

<script>
import getOrderApprovalStatus from '@/utils/getOrderApprovalStatus'

export default {
  name: 'TheMyOrdersTableStatus',
  // TODO mixin
  filters: {
    capitalizeFirstLetter: (val) => {
      if (!val) return val

      const [firstLetter, ...rest] = val

      return [firstLetter.toUpperCase(), ...rest].join('')
    }
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    tooltipsData: [
      {
        name: 'pending-payment',
        tooltip: 'Purchase pending approval from Corporate Gift'
      },
      {
        name: 'pending-approval',
        tooltip: 'Purchase pending approval from Admin'
      },
      {
        name: 'processing',
        tooltip: 'Order has been placed and is being prepared for shipment'
      },
      {
        name: 'shipped',
        tooltip: 'Order has been shipped and is in transit'
      },
      {
        name: 'delivered',
        tooltip: 'Order was successfully delivered to recipient'
      },
      {
        name: 'undeliverable',
        tooltip: 'Delivery attempts failed to reach recipient or package was refused. Order will be returned to warehouse'
      },
      {
        name: 'returned',
        tooltip: 'Delivery attempts failed or package was refused. Order has been returned to warehouse'
      },
      {
        name: 'dedicated-link',
        tooltip: 'E-Gift is set to be sent manually via a dedicated link outside of CG Email service'
      },
      {
        name: 'scheduled',
        tooltip: 'E-Gift is scheduled to be sent on a future date'
      }
    ]
  }),
  computed: {
    orderType () {
      return this.order?.type ?? null
    },
    orderStatus () {
      return this.order?.status ?? null
    },
    orderStatusTooltipData () {
      return this.tooltipsData.find(({ name }) => name === this.orderStatus) ?? null
    },
    showTooltip () {
      return !!this.orderStatusTooltipData
    },
    tooltipText () {
      return this.orderStatusTooltipData?.tooltip ?? null
    },
    orderShipTo () {
      return this.order?.ship_to ?? null
    },
    isOrderShipToAnArray () {
      return Array.isArray(this.orderShipTo)
    },
    numberOfRecipients () {
      return this.orderShipTo?.length ?? 0
    },
    availableRecipients () {
      return this.orderShipTo.filter((recipient) => recipient.first_name || recipient.last_name)
    },
    orderHasApproval () {
      return !!this.order?.approval
    },
    approvalStatus () {
      // Approved | Declined | Auto-approved
      const status = this.order?.approval?.status ?? null

      if (!status) { return null }
      return getOrderApprovalStatus(status)
    },
    approverInfo () {
      const { approvalStatus } = this
      const { approval } = this.order
      const date = this.$options.filters.dateFilter(new Date(approval?.decided_at))
      const approver = approval?.decided_by

      if (approver) { return `${approvalStatus} by: ${approver} on ${date}` }
      return `${approvalStatus} on ${date}`
    },
    approvalInfos () {
      return this.order?.approval?.data ?? []
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  gap: 8px;
  display: flex;
  list-style: none;
  flex-direction: column;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: #000;

  & > li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    word-break: break-word;
    gap: 4px;

    & > b {
      display: flex;
      flex: 0 1 60px;
      min-width: 60px;
      font-family: 'Lato-Bold', sans-serif;
    }

    & a {
      color: #219358 !important;
      white-space: initial;
    }

    & > svg {
      z-index: 2;
    }
  }
}

.order-status-approval-tooltip {
  width: 260px;
  max-width: 260px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;

  &__approver {
    font-family: 'Lato-Bold', sans-serif;
    grid-column: span 2;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }

  &__item {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #262626;

    &:nth-child(even) {
      color: #979797;
    }

    &:nth-child(odd) {
      text-align: right;
    }
  }
}

::v-deep .order-status-tooltip {
  background: #fff;
  padding: 14px;
  font-family: 'Lato-Regular', sans-serif;
  max-width: 220px;
  font-size: 14px;
  line-height: 17px;
  color: #16181F;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
</style>
