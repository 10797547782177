<template>
  <v-row no-gutters class="send-egift-panel-card">
    <v-col :cols="availableItemsLength < 5  ? '4' :'7'">
      <the-campaign-card-images
        :products="availableItemsInGift"
        :defaultImageHeight="230"
        :alternativeImageHeight="115"
        show-products-from-inventory
        :show-all-products-from-inventory="productWillBeTakenFromInventory"
        class="send-egift-panel-card__images"
      />
    </v-col>
    <v-col :cols="availableItemsLength < 5  ? '8' :'5'" class="send-egift-panel-card__info">
      <v-row no-gutters>
        <v-col v-if="loadingCosts" cols="12">
          <common-loader style="padding: 50px 0" />
        </v-col>

        <template v-else>
          <!-- recipients -->
          <v-col cols="12" v-if="!isPageInIframe">
            <v-row no-gutters>
              <v-col
                :cols="availableItemsLength < 5  ? '3' :'5'"
                class="info-item__title info-item__title--green pointer"
                @click="$emit('showRecipients')"
              >
                Recipients
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ recipientsLength }}
              </v-col>
            </v-row>
          </v-col>
          <!-- gift-type -->
          <v-col cols="12">
            <v-row no-gutters>
              <v-col
                :cols="availableItemsLength < 5  ? '3' :'5'"
                class="info-item__title d-flex align-center"
              >
                Gift type

                <popper
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0, 5px' } },
                  }"
                >
                  <div class="popper pa-4 text-left" style="min-width: 150px; max-width: 500px;">
                    {{ getTooltipTextBySlug(getTooltipForGiftType()).replace('${X}', getProductMaxSelection()) }}
                  </div>
                  <div slot="reference" class="d-flex ml-2">
                    <icons-question width="20" height="20" class="pointer" />
                  </div>
                </popper>
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ getGiftTextByType() }}
              </v-col>
            </v-row>
          </v-col>
          <!-- gift options -->
          <v-col cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                Gift options
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ availableItemsLength }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                Max gift cost
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ giftMaxCost | priceFilter }}
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="giftMaxShipping > giftCardFeeTotal" cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                Max shipping
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ giftMaxShipping | priceFilter }}
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="giftSalesTax" cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                Max sales tax
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ giftSalesTax | priceFilter }}
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="egiftFeeTotal" cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                {{ egiftFeeLabel }}
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ egiftFeeTotal | priceFilter }}
              </v-col>
            </v-row>
          </v-col>

          <!-- Dropship fee -->
          <template v-if="dropshipFeeTotal">
            <!-- total -->
            <v-col cols="12">
              <v-row no-gutters>
                <v-col :cols="availableItemsLength < 5  ? '3' :'6'" class="info-item__title">
                  Max Dropship fee
                </v-col>
                <v-col :cols="availableItemsLength < 5  ? '4' :'6'" class="info-item__content">
                  {{ dropshipFeeTotal | priceFilter }}
                </v-col>
              </v-row>
            </v-col>
          </template>

          <v-col v-if="giftCardFeeTotal && giftCardFeeTotal > giftMaxShipping" cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title">
                Gift card fee
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ giftCardFeeTotal | priceFilter }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row no-gutters>
              <v-col :cols="availableItemsLength < 5  ? '3' :'5'" class="info-item__title d-flex align-center">
                Est.cost

                <popper
                  :options="{
                    placement: 'top',
                    modifiers: { offset: { offset: '0, 5px' } },
                  }"
                >
                  <div class="popper pa-4 text-left" style="min-width: 150px; max-width: 500px;">
                    {{ getTooltipTextBySlug('campaigns_total_cost') }}
                  </div>
                  <div slot="reference" class="d-flex ml-2">
                    <icons-question width="20" height="20" class="pointer" />
                  </div>
                </popper>
              </v-col>
              <v-col :cols="availableItemsLength < 5  ? '4' :'7'" class="info-item__content">
                {{ giftTotalCost | priceFilter }}
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import senderName from '../mixins/senderName'
import isPageInIframe from '../mixins/isPageInIframe'
import getTooltipTextBySlug from '@/mixins/getTooltipTextBySlug'

import Popper from 'vue-popperjs'
import TheCampaignCardImages from '@/components/myCampaign/TheCampaignCardImages.vue'
import Api from '@/axios/api'

export default {
  name: 'TheGiftCard',
  components: {
    Popper,
    TheCampaignCardImages
  },
  mixins: [
    senderName,
    isPageInIframe,
    getTooltipTextBySlug
  ],
  props: {
    gift: {
      type: Object,
      required: false
    },
    // TODO mixin
    numberOfRecipients: {
      type: Number,
      required: false,
      default: null
    },
    recipients: {
      type: Array,
      required: true
    }
  },
  data: () =>  ({
    loadingCosts: false,
    campaignCosts: {
      fee: 0,
      fee_per_unit: 0,
      fee_rate: 0,
      fee_type: 'quantity',
      gift_card_fee: 0,
      gift_card_fee_per_unit: 0,
      gift_card_fee_rate: 0,
      shipping: 0,
      shipping_per_unit: 0,
      subtotal: 0,
      subtotal_per_unit: 0,
      dropship_fee: 0,
      dropship_fee_per_unit: 0,
      tax: 0,
      tax_rate: 0,
      total: 0,
    },
  }),
  computed: {
    availableItemsInGift () {
      const { egift_settings: settings, collection: { items } } = this.gift
      if (settings?.egift_type === 'coin') {
        return items.filter(item => item.product.price <= Number(settings?.coin_value || 0))
      }
      return items
    },
    availableItemsLength () {
      return this.availableItemsInGift?.length || 0
    },
    isInventoryEnabled () {
      const { gift: { collection: { inventory: { enabled } } } } = this
      return enabled || false
    },
    inventoryMaxQty () {
      const { gift: { collection: { inventory: { max_qty: max } } } } = this
      return max || 0
    },
    isGiftPrepaid () {
      const { gift: { is_prepaid: prepaid } } = this
      return !!+prepaid || false
    },
    productWillBeTakenFromInventory () {
      const { isInventoryEnabled, inventoryMaxQty, recipientsLength, isGiftPrepaid } = this
      return isGiftPrepaid || (isInventoryEnabled && inventoryMaxQty >= recipientsLength)
    },
    recipientsLength () {
      const { recipients, numberOfRecipients } = this
      return recipients.length || numberOfRecipients || 0
    },
    egiftFeeLabel() {
      const { fee_rate, fee_type } = this.campaignCosts
      return fee_type === 'percent' ? `${fee_rate}% eGift fee` : 'eGift fee'
    },
    // total costs
    giftMaxCost() {
      return this.campaignCosts?.subtotal ?? 0
    },
    giftMaxShipping() {
      return this.campaignCosts?.shipping ?? 0
    },
    giftSalesTax() {
      return this.campaignCosts?.tax ?? 0
    },
    dropshipFeeTotal () {
      return this.campaignCosts?.dropship_fee ?? 0
    },
    egiftFeeTotal() {
      return this.campaignCosts?.fee ?? 0
    },
    giftCardFeeTotal() {
      return this.campaignCosts?.gift_card_fee ?? 0
    },
    giftTotalCost() {
      return this.campaignCosts?.total ?? 0
    },
  },
  watch: {
    giftTotalCost: {
      immediate: true,
      handler: function (val) {
        this.$emit('updateGiftTotalCost', val)
      }
    }
  },
  mounted () {
    this.calculatePrices()
  },
  methods: {
    calculatePrices () {
      const { recipientsLength, gift: { entity_id: id } } = this

      this.loadingCosts = true

      Api.get(`campaigns/send-egift/${id}/calculate`, {
        params: { number_of_recipients: recipientsLength }
      })
        .then((costs) => (this.campaignCosts = costs))
        .catch(() => ({}))
        .finally(() => (this.loadingCosts = false))
    },
    getProductMaxSelection () {
      const { max_allowed_selection: maxSelection } = this.gift.egift_settings
      return maxSelection || 1
    },
    getGiftTextByType () {
      const { egift_type: type } = this.gift.egift_settings
      let giftText

      switch (type) {
        case 'single':
          giftText = 'Single choice'
          break
        case 'multiple':
          giftText = `Multiple choice (${this.getProductMaxSelection()})`
          break
        default:
          giftText = 'GiftCoin'
          break
      }
      return giftText
    },
    getTooltipForGiftType () {
      const { egift_type: type } = this.gift.egift_settings
      let cmsSlugId

      switch (type) {
        case 'single':
          cmsSlugId = 'campaigns_gift_type-single'
          break
        case 'multiple':
          cmsSlugId = 'campaigns_gift_type-multiple'
          break
        default:
          cmsSlugId = 'campaigns_gift_type-coins'
          break
      }
      return cmsSlugId
    }
  }
}
</script>

<style lang="scss" scoped>
.send-egift-panel-card {
  &__images {
    border-top: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    position: relative;
  }

  &__info {
    background-color: #f6f6f6;
    padding: 18px 30px !important;
    text-align: left;

    .info-item {
      &__title {
        font-family: 'Lato-Bold', sans-serif !important;
        font-size: 15px;
        line-height: 27px;
        color: #000;

        &--green {
          color: #219358;
        }
      }

      &__content {
        font-family: 'Lato-Regular', sans-serif !important;
        padding-left: 12px;
        font-size: 15px;
        text-align: right;
        line-height: 27px;
        color: #000;
      }
    }

    .row > .col:not(:last-child) .col {
      border-bottom: 1px solid #E4E4E4;
    }
  }
}
</style>

<style lang="scss">
.send-egift-panel-card {
  &__images {
    & > div > .v-image--hide-border-right {
      border-right: 1px solid #e4e4e4 !important;
    }
  }
}

.egift-fee-link {
  color: #219358 !important;
  line-height: 18px;
  font-family: 'Lato-Regular', sans-serif;
  cursor: pointer;
}
</style>
