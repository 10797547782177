<template>
  <div class="create-campaign-review-card">
    <h2>Campaign details</h2>

    <hr>

    <review-card-edit-input v-model="campaignName" :rules="campaignNameValidation">
      <template #label>Name:</template>
      <template> {{ campaignName }} </template>
    </review-card-edit-input>

    <hr>

    <div class="create-campaign-review-card__text-wrapper">
      <div class="create-campaign-review-card__text">
        <b>Gift options:</b> {{ numberOfSelectedProducts }}
      </div>

      <router-link :to="{ name: 'CreateCampaignSourceReview', params: $route.params }">
        Edit
      </router-link>
    </div>

    <ul>
      <li>From collections: {{ numberOfCollectionsProducts }}</li>
      <li>From favorites: {{ numberOfFavoritesProducts }}</li>
      <li>From inventory: {{ numberOfInventoryProducts }}</li>
    </ul>

    <hr>

    <div class="create-campaign-review-card__text-wrapper">
      <div class="create-campaign-review-card__text" style="word-break: initial">
        <b>Price range:</b> {{ priceRangeText }}
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import campaignNameValidation from '@/components/createCampaign/mixins/campaignNameValidation'

import ReviewCardEditInput from './CreateCampaignReviewCardEditInput.vue'

const { mapState, mapGetters, mapMutations } = createNamespacedHelpers('newCampaign')

export default {
  name: 'CreateCampaignReviewCardDetails',
  mixins: [campaignNameValidation],
  components: { ReviewCardEditInput },
  computed: {
    ...mapState(['form']),
    ...mapGetters(['priceRangeOfSelectedProducts']),
    campaignName: {
      get () { return this.form.name; },
      set (val) { this.setCampaignName(val) },
    },
    selectedProducts () { return this.form.selectedProducts ?? [] },
    numberOfSelectedProducts () { return this.selectedProducts.length },
    numberOfCollectionsProducts () {
      return this.selectedProducts
        .filter(({ isCollection }) => isCollection)
        .length
    },
    numberOfInventoryProducts () {
      return this.selectedProducts
        .filter(({ isInventory }) => isInventory)
        .length
    },
    numberOfFavoritesProducts () {
      return this.selectedProducts
        .filter(({ isInventory, isCollection }) => (!isInventory && !isCollection))
        .length
    },
    allSelectedItemsAreFromInventory () {
      return this.selectedProducts.every(({ isInventory }) => isInventory)
    },
    priceRangeText () {
      const {
        priceRangeOfSelectedProducts,
        numberOfInventoryProducts,
        allSelectedItemsAreFromInventory,
      } = this

      if (allSelectedItemsAreFromInventory) { return 'N/A - all items are from inventory' }

      let result = priceRangeOfSelectedProducts

      if (numberOfInventoryProducts) {
        result += ' *Inventory items cost not included in price range'
      }

      return result
    },
  },
  methods: {
    ...mapMutations(['setCampaignName'])
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  display: flex;
  gap: 16px;
  padding: 0 0 0 16px;
  flex-direction: column;

  font-family: 'Lat-Regular', sans-serif;
  line-height: 21.28px;
  font-weight: 400;
  font-size: 16px;
  color: #62646A;
}
</style>