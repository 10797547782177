<template>
  <div class="settings-panel-payment">
    <div class="settings-panel-payment__title">
      {{ paymentLabel }}
    </div>

    <payment-module
      :paymentMethod.sync="selectedPaymentMethod"
      :paymentData.sync="selectedPaymentData"
      :default-payment-method="selectedPaymentMethod"
      :hide-other-payment-methods="true"
      ref="paymentModule"
    />

    <div
      class="settings-panel-payment__fallback"
      v-if="shouldHaveFallbackPaymentMethod && fallbackPaymentMethod"
    >
      Fallback payment method from {{ fallbackPaymentText }}

      <a @click="changeFallbackMethod = true">Change</a>
    </div>

    <div
      class="settings-panel-payment__fallback settings-panel-payment__fallback--missing"
      v-if="shouldHaveFallbackPaymentMethod && !fallbackPaymentMethod"
    >
      Fallback payment requires credit card details

      <a @click="changeFallbackMethod = true">Add new credit card</a>
    </div>

    <a class="mt-7" @click="showOtherPaymentMethods()">Other payment methods</a>

    <other-payment-methods-panel
      v-model="changeFallbackMethod"
      :loading="isLoading"
      :availableMethods="fallbackPaymentMethods"
      :userSavedPaymentData="savedPaymentData"
      :paymentMethod.sync="selectedFallbackPaymentMethod"
      :paymentData.sync="selectedFallbackPaymentData"
    />
  </div>
</template>

<script>
import PaymentType from '@/components/payment/paymentModule/utils/PaymentType'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'

import PaymentModule from '@/components/payment/PaymentModule.vue'
import OtherPaymentMethodsPanel from '@/components/payment/paymentModule/ThePaymentModuleOtherPaymentsPanel.vue'

export default {
  name: 'SettingsPanelPayment',
  components: {
    PaymentModule,
    OtherPaymentMethodsPanel,
  },
  mixins: [userIsUsingBlueSnap],
  props: {
    shouldHaveFallbackMethod: {
      type: Boolean,
      required: false,
      default: false,
    },
    paymentMethod: {
      type: String,
      required: false,
      default: PaymentType.BE,
    },
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    fallbackPaymentMethod: {
      type: String,
      required: false,
      default: null,
    },
    fallbackPaymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    changeFallbackMethod: false,
    isMounted: false,
  }),
  computed: {
    fallbackPaymentMethods () {
      const { userIsUsingBlueSnap } = this

      if (userIsUsingBlueSnap) {
        return [
          PaymentType.CC_Bluesnap,
          PaymentType.ACH_Bluesnap,
        ]
      } else {
        return [
          PaymentType.CC,
          PaymentType.ACH,
        ]
      }
    },
    selectedPaymentMethod: {
      get () { return this.paymentMethod },
      set (val) { this.$emit('update:paymentMethod', val) },
    },
    selectedPaymentData: {
      get () { return this.paymentData },
      set (val) { this.$emit('update:paymentData', val) },
    },
    selectedFallbackPaymentMethod: {
      get () { return this.fallbackPaymentMethod },
      set (val) { this.$emit('update:fallbackPaymentMethod', val) },
    },
    selectedFallbackPaymentData: {
      get () { return this.fallbackPaymentData },
      set (val) { this.$emit('update:fallbackPaymentData', val) },
    },
    savedPaymentData () {
      const defaultPaymentData = { achs: [], creditCards: [] }

      if (!this.isMounted) { return defaultPaymentData }
      return this.$refs.paymentModule.userSavedPaymentData ?? defaultPaymentData
    },
    isLoading () {
      if (!this.isMounted) { return false }
      return this.$refs.paymentModule.loading ?? false
    },
    convertedFallbackPaymentData () {
      const { fallbackPaymentMethod: method, fallbackPaymentData: data } = this
      const result = {}

      if (method === PaymentType.CC) {
        result.cardType = data?.cardType || data?.card_type || null
        result.cardLastFourDigits = data?.cardLastFourDigits || data?.card_last_four_digits || null
      } else if (method === PaymentType.ACH) {
        result.publicAccountNumber = data?.publicAccountNumber || data?.public_account_number || null
      }

      return result;
    },
    shouldHaveFallbackPaymentMethod () {
      return this.paymentMethod === PaymentType.BE && this.shouldHaveFallbackMethod
    },
    fallbackPaymentText () {
      const { fallbackPaymentMethod, convertedFallbackPaymentData: data } = this

      return fallbackPaymentMethod === PaymentType.CC
        ? `${data.cardType} ****${data.cardLastFourDigits}`
        : `Account Number ${data.publicAccountNumber}`
    },
    paymentLabel () {
      const { paymentMethod } = this

      let paymentName = 'Budget entity balance'

      if ([PaymentType.CC, PaymentType.CC_Bluesnap].includes(paymentMethod)) {
        paymentName = 'Credit card'
      }
      if ([PaymentType.ACH, PaymentType.ACH_Bluesnap].includes(paymentMethod)) {
        paymentName = 'Ach account'
      }

      return `Pay with ${paymentName}`
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    showOtherPaymentMethods () {
      this.$refs.paymentModule.showOtherPaymentMethods()
    },
  }
}
</script>

<style lang="scss" scoped>
.settings-panel-payment {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #000;
  }

  &__fallback {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #A1A1A1;

    &--missing {
      color: #FF5A60;
    }
  }

  a {
    font-family: 'Lato-Italic', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    color: #42B77A !important;
  }

  &::v-deep .cg-payment-module .cg-credits-container > label {
    display: none;
  }
}
</style>
