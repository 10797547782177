export default {
  computed: {
    userName () {
      return this.$store.state.header?.headerData?.customerName ?? null
    },
    userLastName () {
      return this.$store.state.header?.headerData?.customerLastName ?? null
    },
    userFullName () {
      const { userName, userLastName } = this

      return [userName, userLastName]?.join(' ') || null
    }
  }
}
