import { Component, Mixins } from 'vue-property-decorator'
import { IRequestBodyRecipient } from '../../types/sendCampaign'
import type { IRequestBody } from '../../types/sendCampaign'
import { LinkType, PanelType, PaymentType, RecipientMethod } from '../../types'

import getPaymentBody from '@/components/payment/paymentModule/utils/getPaymentBody'

import campaignTotalCostProp from '../mixins/campaignTotalCostProp'
import recipientMethodProp from '../mixins/recipientMethodProp'
import approvalDataProps from '../mixins/approvalDataProps'
import recipientsProp from '../mixins/recipientsProp'
import panelTypeProp from '../mixins/panelTypeProp'
import campaignProp from '../mixins/campaignProp'
import formProp from '../mixins/formProp'
import getOrderSource from '@/utils/getOrderSource'

@Component
export default class GetRequestBody extends Mixins(
  recipientMethodProp,
  campaignTotalCostProp,
  approvalDataProps,
  recipientsProp,
  panelTypeProp,
  campaignProp,
  formProp,
) {
  getRequestBody(): IRequestBody {
    const {
      form,
      recipients,
      singleGiftLinkRecipients,
      recipientMethod,
      panelType,
      checkoutNeedsApproval,
      approvalForm,
      campaignTotalCost,
    } = this

    const requestBody: IRequestBody = {
      allow_to_exchange: false,
      disable_reminder_emails: false,
      allow_to_re_egift: false,
      expire_after: form.expirationDays || 90,
      payment: { method: PaymentType.BE, },
      platformSource: getOrderSource(this),
    }

    const approvalIsSetOnUserLvl: boolean = this.$store.state.header?.headerData?.checkoutNeedsApproval ?? false

    if (recipientMethod === RecipientMethod.UnrestrictedLink) {
      requestBody.number_of_recipients = singleGiftLinkRecipients
    } else {
      requestBody.recipients = recipients.map((recipient): IRequestBodyRecipient => ({
        email: recipient.email || null,
        first_name: recipient.firstName || null,
        last_name: recipient.lastName || null,
        send_date: recipient.sendDate || null,
      }))
    }

    requestBody.allow_to_exchange = form.settings.recipientCanUpgrade
    requestBody.allow_to_re_egift = form.settings.recipientCanReEgift
    requestBody.disable_reminder_emails = form.settings.disableEmailReminders

    requestBody.shipping_date = form.shippingArrivalDate
    requestBody.subject_line = form.emailSubjectLine
    requestBody.video_url = form.videoGreeting
    requestBody.logo_url = form.logoUrl

    if (form.actionLinkType === 'calendly') {
      requestBody.calendar_url = form.calendlyLink
    } else {
      requestBody.free_form_url = form.freeForm?.link
      requestBody.free_form_button = form.freeForm?.buttonText
    }

    requestBody.description = form.description
    requestBody.giver_name = form.giftGiverName
    requestBody.giver_email = form.giftGiverEmail
    requestBody.message = form.greeting

    if (panelType === PanelType.Link) {
      requestBody.link_type = recipientMethod === RecipientMethod.UnrestrictedLink
        ? LinkType.UnrestrictedLink
        : form.linkType
    }

    if (form.linkType === LinkType.SingleLink) {
      requestBody.welcome_message = form.welcomeMessage
    }

    if (approvalIsSetOnUserLvl) {
      requestBody.payment = null
    } else if (campaignTotalCost > 0 || checkoutNeedsApproval) {
      const paymentBody = getPaymentBody(form.payment)
      requestBody.payment = paymentBody
    } else {
      requestBody.payment = {
        method: PaymentType.FREE,
        billingAddress: {},
      }
    }

    if (checkoutNeedsApproval) {
      requestBody.additional_data = approvalForm

      if (requestBody.payment?.method !== PaymentType.BE) {
        requestBody.payment = null;
      }
    }

    return requestBody
  }
}
