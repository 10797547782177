export default {
  data: () => ({
    search: null,
    selectedTag: null,
    campaignId: null,
    creatorId: null,
    sortBy: '-most_used',
    showDisabledCampaigns: false,
    campaignTags: [],
    sortByItems: [
      {
        text: 'Alphabetically',
        value: 'alphabetically'
      },
      {
        text: 'Date added',
        value: '-date_added'
      },
      {
        text: 'Most used',
        value: '-most_used'
      },
      {
        text: 'Automated first',
        value: '-automated'
      }
    ],
  }),
  computed: {
    availableTags () {
      const { campaignTags, selectedTag } = this
      let tags = []

      if (selectedTag !== null) tags.push({ id: null, name: 'All' })
      tags.push(...campaignTags)

      return tags
    },
    queryParams () {
      const { search, selectedTag, sortBy, showDisabledCampaigns, campaignId, creatorId } = this;

      return {
        query: search,
        sort: sortBy,
        id: campaignId,
        created_by: creatorId,
        with_disabled: showDisabledCampaigns,
        tag_ids: selectedTag
      }
    },
  },
  watch: {
    search: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignsSearch', val || null)
      },
    },
    campaignId: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignCampaignId', val || null)
      },
    },
    creatorId: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignCreatorId', val || null)
      },
    },
    selectedTag: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignsSelectedTag', val || null)
      },
    },
    sortBy: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignsSortBy', val || '-most_used')
      },
    },
    showDisabledCampaigns: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignsShowDisabled', val || false)
      },
    },
    campaignTags: {
      immediate: false,
      handler: function (val) {
        sessionStorage.setItem('campaignsTags', JSON.stringify(val || null))
      },
    },
  },
  mounted () {
    this.handleMounted()
  },
  methods: {
    handleMounted () {
      const sessionSearch = sessionStorage.getItem('campaignsSearch') ?? null
      const sessionSelectedTag = sessionStorage.getItem('campaignsSelectedTag') ?? null
      const sessionSortBy = sessionStorage.getItem('campaignsSortBy') ?? '-most_used'
      const sessionShowDisabled = sessionStorage.getItem('campaignsShowDisabled') ?? false
      const sessionCampaignId = sessionStorage.getItem('campaignCampaignId') ?? null
      const sessionCreatorId = sessionStorage.getItem('campaignCreatorId') ?? null
      const sessionTags = JSON.parse(sessionStorage.getItem('campaignsTags'))  ?? []

      if (sessionSearch && sessionSearch !== 'null') this.search = sessionSearch
      if (sessionSortBy && sessionSortBy !== 'null') this.sortBy = sessionSortBy
      if (sessionCampaignId && sessionCampaignId !== 'null') this.campaignId = Number(sessionCampaignId)
      if (sessionCreatorId && sessionCreatorId !== 'null') this.creatorId = Number(sessionCreatorId)
      if (sessionTags?.length) this.campaignTags = sessionTags
      if (sessionSelectedTag && sessionSelectedTag !== 'null')
        this.selectedTag = Number(sessionSelectedTag)
      if (sessionShowDisabled && sessionShowDisabled !== 'null')
        this.showDisabledCampaigns = sessionShowDisabled === 'true' ?? false
    },
  },
}
