<template>
  <div class="loqate-autocomplete">
    <label for="loqate-autocomplete">
      <slot name="label" />
    </label>

    <div
      class="loqate-autocomplete__content"
      :class="{ 'loqate-autocomplete__content--invalid': showError }"
    >
      <input
        type="text"
        id="loqate-autocomplete"
        v-model="autocompleteText"
        :placeholder="placeholder"
        @keydown.tab.prevent="handleTabPress($event)"
        @keydown.up.prevent="handleUpKeyPress()"
        @keydown.down.prevent="handleDownKeyPress()"
        @keydown.enter="selectItem(menuItemIndex)"
        @focus="onFocus()"
      >
    </div>

    <div
      v-show="showMenuDropdown"
      class="loqate-autocomplete__menu"
      ref="autocomplete-menu"
    >
      <div
        class="loqate-autocomplete__menu-item"
        :class="{
          'loqate-autocomplete__menu-item--active': menuItemIndex === index,
          'loqate-autocomplete__menu-item--group': item.Type === 'Container'
        }"
        v-for="(item, index) in availableItems"
        @click="selectItem(index)"
        :key="item.Id"
      >
        {{ item.Text }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

const LoqateApi = axios.create({
  baseURL: 'https://api.addressy.com',
  params: {
    Key: 'UX61-KX96-KP93-BE82'
  }
})

export default {
  name: 'LoqateAutocomplete',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Start typing your street address for suggestions',
    },
    street: {
      type: String,
      required: false,
      default: null,
    },
    apt: {
      type: String,
      required: false,
      default: null,
    },
    city: {
      type: String,
      required: false,
      default: null,
    },
    region: {
      type: String,
      required: false,
      default: null,
    },
    postcode: {
      type: String,
      required: false,
      default: null,
    },
    country: {
      type: String,
      required: false,
      default: null,
    },
    locationType: {
      type: Number,
      required: false,
      default: null,
    },
      /**
       * An array of available countries for Loqate
       * Empty Array means all countries are available
       */
    availableCountries: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data: () => ({
    showMenu: false,
    showError: false,
    menuItemIndex: null,
    availableItems: [],
  }),
  computed: {
    showMenuDropdown () {
      const { autocompleteText, availableItems, showMenu } = this
      return showMenu && autocompleteText && availableItems.length
    },
    autocompleteText: {
      get () { return this.street },
      set (val) { this.$emit('update:street', val) }
    },
    aptInput: {
      get () { return this.apt },
      set (val) { this.$emit('update:apt', val) }
    },
    cityInput: {
      get () { return this.city },
      set (val) { this.$emit('update:city', val) }
    },
    regionInput: {
      get () { return this.region },
      set (val) { this.$emit('update:region', val) }
    },
    postalCodeInput: {
      get () { return this.postcode },
      set (val) { this.$emit('update:postcode', val) }
    },
    countryInput: {
      get () { return this.country },
      set (val) { this.$emit('update:country', val) }
    },
    locationTypeInput: {
      get () { return this.locationType },
      set (val) { this.$emit('update:locationType', val) }
    },
  },
  watch: {
    autocompleteText: function (val) {
      this.showError = false;
      this.getLoqateAddresses({ text: val })
    },
  },
  mounted() {
    document.addEventListener('click', this.clickHandler);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickHandler);
  },
  methods: {
    async getLoqateAddresses ({ text, container = null }) {
      const country = this.availableCountries.filter((country) => country).join(', ')

      LoqateApi.get('/Capture/Interactive/Find/v1.10/json3.ws', {
        params: {
          Text: text,
          Container: container,
          Countries: country,
          Limit: 20,
          Language: 'en-us',
        }
      })
      .then(({ data: { Items } }) => {
        this.availableItems = Items[0]?.Error ? [] : Items
        const autocompleteMenu = this.$refs['autocomplete-menu']
        if (autocompleteMenu) autocompleteMenu.scrollTop = 0
      })
    },
    async getLoqateAddressInfo ({ id }) {
      LoqateApi.get('/Capture/Interactive/Retrieve/v1.00/json3ex.ws', { params: { Id: id } })
        .then(({ data: { Items } }) => {
          const [item] = Items
          this.autocompleteText = item?.Line1
          this.cityInput = item?.City
          this.aptInput = item?.SubBuilding
          this.postalCodeInput = item?.PostalCode
          this.countryInput = item?.CountryIso2
          this.regionInput = item?.ProvinceName || item?.ProvinceCode
          this.locationTypeInput = item?.Type
          this.showMenu = false
          this.$emit('onSelectedAddress')
        })
    },
    clickHandler (event) {
      const { target } = event;
      const { $el } = this;

      if (!$el.contains(target)) {
        this.showMenu = false;
      }
    },
    onFocus () {
      this.showMenu = true
      this.menuItemIndex = null
    },
    handleTabPress ({ shiftKey }) {
      if (shiftKey) {
        this.handleUpKeyPress()
      } else {
        this.handleDownKeyPress()
      }
    },
    handleUpKeyPress () {
      const { menuItemIndex, availableItems: { length } } = this
      if (menuItemIndex === null) {
        this.menuItemIndex = 0
      } else {
        this.menuItemIndex = menuItemIndex === 0 ? length - 1 : menuItemIndex - 1
      }
      this.setAutocompleteMenuScroll('up')
    },
    handleDownKeyPress () {
      const { menuItemIndex, availableItems: { length } } = this
      if (menuItemIndex === null) {
        this.menuItemIndex = 0
      } else {
        this.menuItemIndex = menuItemIndex === length - 1 ? 0 : menuItemIndex + 1
      }
      this.setAutocompleteMenuScroll('down')
    },
    setAutocompleteMenuScroll (arrowEvent = 'down') {
      const { menuItemIndex } = this
      const autocompleteMenu = this.$refs['autocomplete-menu']

      if (menuItemIndex === 0) {
        autocompleteMenu.scrollTop = 0
      } else {
        if (arrowEvent === 'down') autocompleteMenu.scrollTop += 40
        if (arrowEvent === 'up') autocompleteMenu.scrollTop -= 40
      }
    },
    selectItem (index) {
      const { availableItems, autocompleteText } = this
      const selectedItem = availableItems[index]
      
      if (selectedItem.Type !== 'Address') {
        this.getLoqateAddresses({ text: autocompleteText, container: selectedItem.Id })
      } else {
        this.getLoqateAddressInfo({ id: selectedItem.Id })
      }
    },
    validate () {
      this.showError = true
      return false
    }
  },
}
</script>

<style lang="scss" scoped>
.loqate-autocomplete {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
    z-index: 3;
    padding: 0 10px;
    height: 40px;
    background: #fff;
    border: 1px solid #D3D2D2;
    transition: border-color ease-in-out 0.18s,
      background ease-in-out 0.18s;

    input {
      margin: 0;
      border: none;
      padding: 0;
      outline: none;

      flex: 1 1 auto;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      z-index: 1;
      box-sizing:border-box;

      font-family: 'Lato', sans-serif;
      font-size: 15px;
      line-height: 22px;
      background: transparent;
      color: #222325;

      &::placeholder {
        font-family: 'Lato', sans-serif;
        font-style: italic;
        color: #95979D;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &:focus-within {
      border: 1px solid #A7DAD8;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        border: 1px solid #A7DAD8;
        position: absolute;
        box-sizing: border-box;
      }
    }

    &--invalid {
      background: #FBE7E9;
      border-color: #FF5A60;

      &::before {
        content: none;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 320px;
    overflow-y: auto;
    border: 1px solid #D3D2D2;
    border-top: none;
    background-color: #fff;
    z-index: 4;
  }

  &__menu-item {
    padding: 4px 14px;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    box-sizing: inherit;
    color: #222325;
    background-color: #fff;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background-color: #FAFAFA;
    }

    &--active {
      background-color: #EDFCFB;
    }

    &--group {
      &::after {
        width: 8px;
        min-width: 8px;
        height: 8px;
        content: '';
        transform: rotate(-45deg) translateY(-4px) translateX(4px);
        border-left: 2px solid #95979D;
        border-bottom: 2px solid #95979D;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #D3D2D2;
    }
  }

  & > label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #222325;
    text-align: left;
  }
}
</style>
