<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
  >
    <div class="create-campaign-add-more-gifts__wrapper">
      <div class="create-campaign-add-more-gifts__content">
        <h1>Select a gift source</h1>

        <create-campaign-source-list />

        <cg-alert type="information" background-color="#F6F5FF">
          <b>Note:</b> Max 250 gifts per campaign
        </cg-alert>
      </div>

      <v-icon color="white" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import panelVModel from '@/mixins/panelVModel'

import { CgAlert } from '@corporategift/design-system'
import CreateCampaignSourceList from './CreateCampaignSourceList.vue'

export default {
  name: 'CreateCampaignSourceReviewAddMoreGiftsModal',
  mixins: [panelVModel],
  components: {
    CgAlert,
    CreateCampaignSourceList,
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-add-more-gifts {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: fit-content;
    margin-inline: auto;
  }

  &__content {
    gap: 40px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px 60px;

    & > h1 {
      font-family: 'Lato-Light', sans-serif;
      font-weight: 300;
      font-size: 40px;
      line-height: 65px;
      color: #000000;
    }

    &::v-deep > .cg-alert {
      width: 100%;

      & > .cg-alert__content {
        font-family: 'Lato-Regular', sans-serif;
        font-weight: 400;

        & > b {
          font-family: 'Lato-Bold', sans-serif;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
