<template>
  <v-app>
    <common-sidebar v-if="!(hideUserMenu || $vuetify.breakpoint.xsOnly)" />
    <cg-header
      v-show="!hideHeader"
      app
      :fixed="true"
      :left="$vuetify.application.left"
      @update:hideHeader="(val) => isHeaderHidden = val"
    />

    <v-main
      :style="{ 'padding-top': !hideHeader ? `${headerHeight}px` : 0 }"
      :class="{ 'v-main--dashboard': userIsOnDashboardPage }"
    >
      <ul
        v-if="hasBreadcrumbs"
        class="v-application__breadcrumbs"
      >
        <li v-for="(breadcrumb, index) in pageBreadcrumbs" :key="index">
          <router-link v-if="!breadcrumb.url.startsWith('https')" :to="breadcrumb.url">
            {{ breadcrumb.name }}
          </router-link>

          <a v-else :href="breadcrumb.url">
            {{ breadcrumb.name }}
          </a>
        </li>
      </ul>

      <router-view />
    </v-main>

    <the-app-snackbar />
    <the-preview-video-modal />
  </v-app>
</template>

<script>
import TheAppSnackbar from './components/TheAppSnackbar.vue'
import ThePreviewVideoModal from './components/ThePreviewVideoModal.vue'

import getCookie from '@/utils/getCookie'
import createCookieForSameDomain from '@/utils/createCookieForSameDomain'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'
import { custom } from '@/plugins/googleTagManager'

export default {
  name: 'App',
  mixins: [userIsUsingBlueSnap],
  components: {
    TheAppSnackbar,
    ThePreviewVideoModal,
  },
  data: () => ({
    isHeaderHidden: false,
    headerObserverHeight: null,
  }),
  computed: {
    hideUserMenu () {
      return this.$store.state.app.hideUserMenus
    },
    hideHeader () {
      return this.$store.state.app.hideHeader
    },
    headerHeight () {
      const { headerObserverHeight } = this
      if (headerObserverHeight) {
        return headerObserverHeight || 163
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 66 : 163
      }
    },
    pageBreadcrumbs () {
      return this.$route.meta?.breadcrumbs ?? []
    },
    hasBreadcrumbs () {
      return !!this.pageBreadcrumbs?.length ?? false
    },
    userIsOnDashboardPage () {
      return this.$route.name === 'Dashboard'
    },
  },
  watch: {
    headerHeight: {
      immediate: true,
      handler: function (val) { this.$header.height = val }
    },
    isHeaderHidden: {
      immediate: true,
      handler: function (val) { this.$header.isVisible = !val }
    },
    userIsUsingBlueSnap: {
      immediate: true,
      handler: 'loadPaymentGatewayScript'
    }
  },
  created () {
    this.$store.dispatch('tooltips/getTooltips', null)
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      const { type, payload } = mutation
      if (type === 'header/setHeader') {
        this.$store.dispatch('app/setUserInfo', payload)
      }

      if (type === 'headerAuth/clearToken') {
        if (this.$route.meta.requireAuth) {
          window.location.href = `${process.env.VUE_APP_MAGENTO_URL}`
        }
      }
    })

    this.setHeaderResizeObserver()
    this.handleAnalyticsForLoginByGoogle()
  },
  methods: {
    loadPaymentGatewayScript () {
      const { isScriptLoaded, loadBraintreeScript, loadBluesnapScript, userIsUsingBlueSnap } = this

      const brainTreeScriptName = 'js.braintreegateway.com/web/3.99.0/js/client.min.js'
      const bluesnapScriptName = 'web-sdk/4/bluesnap.js'

      if (userIsUsingBlueSnap) {
        if (!isScriptLoaded(bluesnapScriptName)) {
          loadBluesnapScript()
        }
      } else {
        if (!isScriptLoaded(brainTreeScriptName)) {
          loadBraintreeScript()
        }
      }
    },
    isScriptLoaded (scriptUrlOrPart) {
      const scripts = [...document.getElementsByTagName('script')]
      const result = scripts.map(script => script.src.includes(scriptUrlOrPart)).includes(true)
      return result
    },
    loadBraintreeScript() {
      const paypalScript = document.createElement('script');
      const paypalUrl = 'https://www.paypalobjects.com/api/checkout.js';
      paypalScript.setAttribute('type', 'text/javascript');
      paypalScript.setAttribute('data-version-4', '');
      paypalScript.setAttribute('src', `${paypalUrl}`);
      document.head.append(paypalScript);

      const braintreeScript = document.createElement('script');
      const braintreeUrl = 'https://js.braintreegateway.com/web/3.99.0/js/client.min.js';
      braintreeScript.setAttribute('type', 'text/javascript');
      braintreeScript.setAttribute('src', `${braintreeUrl}`);
      document.head.append(braintreeScript);

      const braintreeFieldsScript = document.createElement('script');
      const braintreeFieldsUrl = 'https://js.braintreegateway.com/web/3.99.0/js/hosted-fields.min.js';
      braintreeFieldsScript.setAttribute('type', 'text/javascript');
      braintreeFieldsScript.setAttribute('src', `${braintreeFieldsUrl}`);
      document.head.append(braintreeFieldsScript);

      const braintreeDataCollector = document.createElement('script');
      const braintreeDataCollectorUrl = 'https://js.braintreegateway.com/web/3.99.0/js/data-collector.min.js';
      braintreeDataCollector.setAttribute('type', 'text/javascript');
      braintreeDataCollector.setAttribute('src', `${braintreeDataCollectorUrl}`);
      document.head.append(braintreeDataCollector);

      const braintreeUsBank = document.createElement('script');
      const braintreeUsBankUrl = 'https://js.braintreegateway.com/web/3.99.0/js/us-bank-account.min.js';
      braintreeUsBank.setAttribute('type', 'text/javascript');
      braintreeUsBank.setAttribute('src', `${braintreeUsBankUrl}`);
      document.head.append(braintreeUsBank);

      const braintree2Script = document.createElement('script');
      const braintree2Url = 'https://js.braintreegateway.com/web/3.99.0/js/paypal-checkout.min.js';
      braintree2Script.setAttribute('type', 'text/javascript');
      braintree2Script.setAttribute('src', `${braintree2Url}`);
      document.head.append(braintree2Script);
    },
    loadBluesnapScript () {
      const bluesnapScript = document.createElement('script')
      const bluesnapUrl = process.env.NODE_ENV === 'production' ? 'https://ws.bluesnap.com' : 'https://sandbox.bluesnap.com'
      bluesnapScript.setAttribute('type', 'text/javascript')
      bluesnapScript.setAttribute('src', `${bluesnapUrl}/web-sdk/4/bluesnap.js`)
      document.head.append(bluesnapScript)
    },
    setHeaderResizeObserver () {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target.className?.includes('cg-header')) {
            const header = entry.target.querySelector('header')
            this.headerObserverHeight = header?.offsetHeight ?? entry.contentRect.height
          }
        }
      })

      const headerWrapper = document.querySelector('.cg-header')
      resizeObserver.observe(headerWrapper)
    },
    handleAnalyticsForLoginByGoogle () {
      const cgSingUp = getCookie('cgSignUp')
      const cgLogin = getCookie('cgLogin')

      if (cgSingUp || cgLogin) {
        const datLayerObject = JSON.parse(cgSingUp || cgLogin)

        // custom event for Google Tag Manager
        custom(datLayerObject)

        createCookieForSameDomain('cgSignUp', null, 0)
        createCookieForSameDomain('cgLogin', null, 0)
      }
    },
  }
}
</script>

<style lang="scss">
@import "./css/index.scss";

* {
  font-family: 'Lato-Regular';
}

.grecaptcha-badge {
  visibility: hidden;
}

.v-application--wrap {
  min-height: 100vh !important;
}

.mdi-magnify::before, .mdi-close::before {
  font-size: 19px;
}

.v-main {
  &--dashboard {
    background: #FAFAFA;
  }
}

.v-application__breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 14px;
  list-style: none;
  padding: 14px 64px 0 !important;
  gap: 4px;
  margin: 0;
  padding: 0;

  & > li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4px;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.16px;
    color: #95979D;

    &:not(:last-child)::after {
      content: '/';
    }

    & > a {
      color: inherit !important;
      text-decoration: none;
    }
  }
}

.vc-text {
  font-size: 18px!important;
  padding: 0 20px!important;
  margin-top: 16px!important;
}

.vc-btn {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }
}

.vc-btn:last-child {
  background: red;
  color: white;

  &:hover {
    background: red;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 26px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D3D2D2;
  border-radius: 26px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #95979D;
}
</style>
