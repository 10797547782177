import PaymentType from './PaymentType'

export default function getPaymentBody ({ method, paymentData }) {
  const paymentBody = {}
  paymentBody.method = method

  const braintreeDeviceData = JSON.parse(sessionStorage.getItem('braintreeDeviceData'))
  const deviceSessionId = braintreeDeviceData?.device_session_id

  if (method === PaymentType.BE) {
    paymentBody.budget_id = paymentData?.id;
  } else if (method === PaymentType.PO) {
    paymentBody.poNumber = paymentData?.poNumber;
    if (paymentData?.fileLink) {
      paymentBody.poFile = paymentData.fileLink
      paymentBody.poFileName = paymentData?.poFileName ?? 'po-file'
    }
  } else if (method === PaymentType.ACH) {
    paymentBody.token = paymentData?.token
    paymentBody.deviceData = deviceSessionId ?? null
  } else if (method === PaymentType.CC) {
    paymentBody.deviceData = deviceSessionId ?? null

    if (Object.hasOwn(paymentData, 'token')) {
      paymentBody.token = paymentData?.token
    } else {
      paymentBody.paymentMethodNonce = paymentData?.payment?.paymentMethodNonce
      paymentBody.billingAddress = paymentData?.billingAddress
      paymentBody.bin = paymentData?.binNumber
    }
  } else if (method === PaymentType.CC_Bluesnap) {
    paymentBody.card_last_four_digits = paymentData?.cardLastFourDigits
    paymentBody.card_type = paymentData?.cardType
    paymentBody.bin = paymentData?.binNumber
  } else if (method === PaymentType.ACH_Bluesnap) {
    paymentBody.public_routing_number = paymentData?.publicRoutingNumber
    paymentBody.public_account_number = paymentData?.publicAccountNumber
  }

  return paymentBody
}
