<template>
  <div class="payment-module-po-container">
    <cg-input v-model="form.poNumber">
      <template #label>
        Purchase order
      </template>

      <template #append-icon>
        <icons-check-circle
          v-if="form.poNumber"
          :height="20"
          :width="20"
          filled
        />
      </template>
    </cg-input>

    <div class="upload-file-input__wrapper">
      <label class="upload-file-input__label" for="po-file">Upload PO PDF</label>

      <label class="upload-file-input__content">
        <input
          type="file"
          id="po-file"
          accept=".jpg, .jpeg, .png, .pdf"
          @change="onFileUpdate"
        >

        <div :class="{ 'upload-file-input__placeholder': !poFileName }">
          {{ poFileName || 'Upload PDF file' }}
        </div>

        <div>
          <icons-upload-file :width="16" :height="16" />
        </div>
      </label>
    </div>

    <cg-button :disabled="disableContinueButton" @click="onContinue">Continue</cg-button>
  </div>
</template>

<script>
import Api from '@/axios/api'
import { CgInput, CgButton } from '@corporategift/design-system'

export default {
  name: 'PayWithPo',
  components: {
    CgInput,
    CgButton,
  },
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    form: {
      poNumber: null,
      poFile: null,
      fileLink: null,
    },
  }),
  computed: {
    disableContinueButton () {
      return !Object.values(this.form)
        .every((value) => value !== null)
    },
    poFileName () {
      const { poFile } = this.form
      return poFile ? poFile.name : null
    },
  },
  created () {
    const { paymentData } = this

    if (paymentData?.fileLink && paymentData?.poNumber) {
      this.form = structuredClone(paymentData);
    }
  },
  methods: {
    onFileUpdate () {
      const file = this.$el.querySelector('#po-file')?.files?.[0] ?? null

      if (file) {
        const formData = new FormData();
        formData.append('file', file)
        this.form.poFile = file

        Api.post('checkout/payment/po-upload', formData)
          .then(({ data }) => {
            this.form.poFile = file
            this.form.fileLink = data.path
          })
          .catch(() => {
            this.form.poFile = null
            this.form.fileLink = null
          })
      } else {
        this.form.poFile = null
        this.form.fileLink = null
      }
    },
    onContinue () {
      const { form } = this

      this.$emit('onPoSelect', 'purchaseorder', form);
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-module-po-container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  & .cg-button {
    border-radius: 100px;
  }

  &::v-deep input[type=file] {
    color: transparent;

    &::file-selector-button {
      display: none;
    }

    &:valid {
      color: #222325;
    }
  }
}

.upload-file-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__label {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: #222325;
    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px;
    border: 1px solid #D3D2D2;
    background: #fff;
    gap: 8px;
    height: 40px;
    position: relative;
    box-sizing: border-box;
    transition: border-color ease-in-out 0.18s,
      background ease-in-out 0.18s;

    & > input {
      display: none;
    }

    & > div {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      line-height: 22px;
      color: #222325;

      &:nth-child(odd) {
        flex: 0 1 20px;
      }

      &.upload-file-input__placeholder {
        font-family: 'Lato', sans-serif;
        color: #95979D;
        font-style: italic;
        line-height: 18px;
      }
    }

    &:focus-within {
      border: 1px solid #A7DAD8;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        border: 1px solid #A7DAD8;
        position: absolute;
        box-sizing: border-box;
      }
    }
  }
}
</style>
