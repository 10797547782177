<template>
  <router-link
    role="button"
    :disabled="disabled"
    :to="{ name: routerName, params: $route.params }"
    :tabindex="disabled ? -1 : 0"
    class="create-campaign-source-item"
    :aria-disabled="disabled ? 'true' : 'false'"
  >
    <div
      class="create-campaign-source-item__icon"
      :aria-disabled="disabled ? 'true' : 'false'"
      :style="`--icon-background-color: ${iconBackgroundColor}`"
    >
      <slot name="icon" />
    </div>

    <div class="create-campaign-source-item__content">
      <slot />

      <create-campaign-cg-elite-badge v-if="showCgMembersBadge" />
    </div>
  </router-link>
</template>

<script>
import userIsCgElite from '@/mixins/userIsCgElite'

import CreateCampaignCgEliteBadge from './CreateCampaignCgEliteBadge.vue'

export default {
  name: 'CreateCampaignSourceItem',
  mixins: [userIsCgElite],
  components: { CreateCampaignCgEliteBadge },
  props: {
    routerName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconBackgroundColor: {
      type: String,
      required: false,
      default: '#AEFAFF',
    },
    isOnlyCgElite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    showCgMembersBadge () {
      const { userIsCgElite, isOnlyCgElite, disabled } = this;

      if (userIsCgElite) { return false }
      return isOnlyCgElite && disabled
    },
  },
}
</script>

<style lang="scss" scoped>
.create-campaign-source-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px 20px;
  width: 230px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  color: #222325;
  text-align: center;
  font-family: 'Lato-Italic', sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
  border: 4px solid transparent;
  transition: background-color ease-in-out 0.36s;

  &:hover,
  &:focus {
    background-color: #F6F5FF;
    outline: none;
  }

  &:active {
    border: 4px solid #000;
  }

  &[aria-disabled="true"] {
    cursor: default;
    pointer-events: none;
    background-color: #F4F4F4;
  }

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    color: #222325;
    align-items: center;
    justify-content: center;
    background-color: var(--icon-background-color, #AEFAFF);

    &[aria-disabled="true"] {
      background-color: #D3D2D2;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}
</style>
